import React from 'react'
import styles from '../styles/matchsummary.css';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';



const MatchSummary = () => {
  useEffect(() => {
    window.opta_settings= {
			subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
			language: 'en_GB',
			timezone: 'Europe/London',
			link_callback: function (params) {
				console.log(params);
//				return params.base_url;

			}
		};
  }, [])
    const location = useLocation();
    const matchSummaryWidget = Object.fromEntries(new URLSearchParams(location.search));

  return (
    <div>
      <opta-widget style={styles} widget={matchSummaryWidget.widget} competition={matchSummaryWidget.competition} season={matchSummaryWidget.season} match={matchSummaryWidget.match} template={matchSummaryWidget.template} live={matchSummaryWidget.live} show_match_header={matchSummaryWidget.show_match_header} show_score={matchSummaryWidget.show_score} show_attendance={matchSummaryWidget.show_attendance} show_date={matchSummaryWidget.show_date} date_format={matchSummaryWidget.date_format} show_cards={matchSummaryWidget.show_cards} show_crests={matchSummaryWidget.show_crests} show_team_formation={matchSummaryWidget.show_team_formation} show_goals={matchSummaryWidget.show_goals} show_goals_combined={matchSummaryWidget.show_goals_combined} show_penalties_missed={matchSummaryWidget.show_penalties_missed} show_halftime_score={matchSummaryWidget.show_halftime_score} show_referee={matchSummaryWidget.show_referee} show_subs={matchSummaryWidget.show_subs} show_venue={matchSummaryWidget.show_venue} show_shootouts={matchSummaryWidget.show_shootouts} show_tooltips={matchSummaryWidget.show_tooltips} show_images={matchSummaryWidget.show_images} show_competition_name={matchSummaryWidget.show_competition_name} competition_naming={matchSummaryWidget.competition_naming} team_naming={matchSummaryWidget.team_naming} player_naming={matchSummaryWidget.player_naming} show_live={matchSummaryWidget.show_live} show_logo={matchSummaryWidget.show_logo} show_title={matchSummaryWidget.show_title} breakpoints={matchSummaryWidget.breakpoints} sport={matchSummaryWidget.sport} ></opta-widget>
      <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
    </div>
  )
}

export default MatchSummary
