import React from 'react';
import { useState } from 'react';
import './accordian.css'

const FaqAccordian = () => {

    const [selected, setSelected] = useState(null)

    const toggle = i => {
        if (selected === i) {
            return setSelected(null)
        }


        setSelected(i)
    }

    return (
        <div className='wrapper'>
            <div className='accordian'>
                {data.map((item, i) => (
                    <div className='item'>
                        <div className='title' onClick={() => toggle(i)}>
                            <p>{item.question}</p>
                            <span className='plus'>{selected === i ? '-' : '+'}</span>
                        </div>
                        <div
                            className={
                                selected === i ? 'content show' : 'content'
                            }
                        >
                            {item.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const data = [

    {
        question: 'What is VUSPORT?',
        answer: 'VUSport is an online platform devoted to all things sports. Live Sports Streaming, Content-On-Demand and Sports Analytics for Daily Fantasy Sport',

    },
    {
        question: 'Where can I access the VUSPORT App?',
        answer: 'For Android Users, you can download the VUSPORT App from the Android Play Store and for IPhone Users, you can download the VUSPORT App from the Apple Store',

    },
    {
        question: 'I am unable to login using my mobile number. What should I do?',
        answer: 'While logging in with your mobile number, please ensure that the number you entered is correct. If you see an error message, please double-check the number and try again. If your mobile number is not verified, feel free to log in for now using your registered email ID, Facebook, or Google. Make sure to verify your mobile number once you log in.',
    },
    {
        question: 'I am not receiving any OTP. What should I do?',
        answer: "You should receive an OTP (One Time Password) within about 2 minutes of entering your mobile number. Please ensure that you’ve double-checked the mobile number that's entered, and your network connectivity is strong enough to receive an SMS. If you still don’t receive the OTP, please contact your network service provider. If the problem still persists, contact us here helpdesk@vusport.com",

    },
    {
        question: "I forgot the mobile number I used to login to my VUSPORT profile. How can I log in?",
        answer: "If you forgot your verified mobile number, log in using your Facebook or Google account instead. If you need more help figuring out your login credentials, contact us here helpdesk@vusport.com",

    },
    {
        question: "I forgot the email id I used to login to my VUSPORT profile. How can I log in?",
        answer: "If you forgot your registered email ID, log in using your verified mobile number instead. An OTP will be sent to you for verification! If you need more help figuring out your login credentials, do contact us here helpdesk@vusport.com",

    },
    {
        question: "Which leagues can we watch on VUSPORT? ",
        answer: "We are presently in the midst of acquiring rights for multiple leagues across sports to build a robust portfolio which our users can have access to. Presence of such leagues on the platform will help sports fans like yourself to follow the league and the games more closely and engage on the same. Stay tuned on our social media pages to know more",

    },
    {
        question: "I am traveling abroad for personal / professional reasons. Can I watch the streaming of LIVE events when I am traveling?",
        answer: "Unfortunately, all games streamed on VUSPORT are accessible in the Indian Geography only",

    },
    {
        question: "Which leagues does VUSPORT Stream?",
        answer: "VUSPORT streams only those leagues across Cricket and Football for which it is the official rights holder as sanctioned by the respective official sporting body of that event",

    },
    {
        question: "Which leagues has VUSPORT Streamed so far in Cricket?",
        answer: "VUSPORT was the official broadcaster of the Nepal Club Cricket Championship in Association with Cricket Nepal, Saurashtra Premier League Season 2, UAE Tour of Bangladesh, ICC U19 Womens T20 World Cup Qualifiers Africa Leg, Kwibuka Womens T20 Cup",

    },
    {
        question: "Which leagues has VUSPORT Streamed so far in Football?",
        answer: "VUSPORT is the official broadcaster of the A-League in India along with the Copa Libertadores and Copa Sudamericana for India",

    },
    {
        question: "What is Sports Analytics on VUSPORT all about?",
        answer: "We firmly believe that numbers don’t lie and there is merit in backing them while making decisions be it in any walk of life. Through Sports Analytics on VUSPORT, our intent is to make these data points available to the average Indian Sports Fan in an easy manner. Our intent is to make sports fans leverage the power of data and follow sport in a deeper, richer and more engaged manner. Additionally, we wish to help the Indian Daily Fantasy Sports (DFS) Users to improve their skill in the game of skill ",

    },
    {
        question: "What is Daily Fantasy Sports (DFS)",
        answer: "Daily fantasy sports (DFS) are a subset of fantasy sport games where fantasy managers compete against each other by building a team of professional athletes from a particular competition with a constraint of a salary cap and earn fantasy points based on the actual performance of the players in real world competitions",

    },
    {
        question: "Is Fantasy Sports Legal in India?",
        answer: "Yes, Fantasy Sports is Legal in India as it is a game of skill which is legal.",
    },
    {
        question: "Am I allowed to play if I’m under the age of 18?",
        answer: "No Fantasy Sports app in India allows any user below 18 to participate or use their services",
    },
    {
        question: "Where do you get your Cricket Data from?",
        answer: "We have subscribed to official data sources for Cricket which collect and help us with ball-by-ball data points regarding various cricket events that happen across the globe",

    },
    {
        question: "Where do you get your Football Data from?",
        answer: "Our football data is powered by OPTA SPORTS which generates data in real-time though a combination of human annotation, computer vision and AI modelling",

    },
    {
        question: "What is the VUSPORT Algorithm?",
        answer: "VUSPORT Algorithm is our in-house proprietary tool that looks at past data points to cull out insights & spot trends that helps us do predictive modeling & prepare our best combination for a fantasy cricket and a fantasy football game",

    },
    {
        question: "How do you suggest the fantasy 11?",
        answer: "Our fantasy 11 is suggested basis the input given by the VUSPORT Algorithm",
    },
    {
        question: "What kind of content can I catch on VUSPORT?",
        answer: "VUSPORT Content is being tailor made to suit the casual sports fan in the country to stay engaged on Sports and to bring them closer to the same. The idea is to unlock different levers which will help fans to build keen interest in Sports and to make more and more people to follow more sport and watch more sport",

    },
    {
        question: "I am not able to stream the match. What should I do?",
        answer: "Please do check your internet connection once if it is working fine. If it is and you are still unable to stream the match, you can reach us here helpdesk@vusport.com",

    },
    {
        question: "What is the resolution of the video feed you provide?",
        answer: "Our endeavour is to provide the highest quality streaming available for all the matches, but it also depends on the league and the coverage we're receiving from the feed provider. Please note that the resolution also depends on your network speed so suggest that you wait for some time or try to switch to a better network.",

    },
    {
        question: "Can I stream the live match on my desktop/laptop?",
        answer: "Unfortunately in the current scenario, you can only stream the games on the VUSPORT App",
    },
    {
        question: "I don't see the option of live streaming for a few matches. Why?",
        answer: "Unfortunately, we may not have the rights to stream those matches.",
    },
    {
        question: "Can I live stream the matches on Fantasy Akhada?",
        answer: "All those matches which are free for everyone would be available on Fantasy Akhada",
    },
    {
        question: "Can I cast the match's live streaming on my Smart TV?",
        answer: "Yes, If your Smart TV has in-built Chromecast or it is plugged in with an external Chromecast device, you can live stream and enjoy your favourite game on TV by casting it from the VUSPORT app on your Android device. Please make sure your mobile and Smart TV are connected on the same network. Please note that this feature is NOT available on iOS as of now. Stay tuned to the app for further updates.",

    },


]

export default FaqAccordian
