import React from 'react'
import '../../scss/privacyPolicy.scss'

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy-page' style={{ padding: '0px 20px 20px 20px', maxWidth: '1100px', margin: 'auto', }}>
            <p className='first' dir="ltr"><strong>THIS PRIVACY POLICY COMPLIES WITH THE REQUIREMENTS OF INDIAN LAW AND APPLIES TO PERSONS WHO ARE CITIZENS OF INDIA AND ARE RESIDENTS IN INDIA OR ENTITIES WHICH ARE INCORPORATED IN INDIA. IF YOU ARE LOCATED IN ANY OTHER JURISDICTION AND DO NOT COMPLY WITH THE REQUIREMENTS SET OUT ABOVE. YOU MAY NOT SHARE PERSONAL INFORMATION OR USE THE SERVICES EXCEPT WITH THE PRIOR WRITTEN CONSENT OF THE COMPANY IN WRITING.</strong></p>
            <p dir="ltr">The website located at <a href="https://www.vusport.com">www.vusport.com</a> and the mobile and television application VUSport (the &ldquo;Platform&rdquo;) is owned and operated by the Super Six Sports Gaming Private. Limited (&ldquo;Company&rdquo;), Through the Platform, the Company provides users with, information regarding the online fantasy sports games hosted by the Company, its features and activities, and offers a go-to-guide for users to obtain detailed match previews, reviews and informative articles (&ldquo;Subscription Service&rdquo;) and access to live streaming of matches and tournaments of certain sports (&ldquo;Streaming Service&rdquo;) collectively, the &ldquo;Services&rdquo;). This Privacy Policy (&ldquo;Privacy Policy&rdquo;) describes the use and processing of information by the Company.&nbsp;</p>
            <p dir="ltr">The terms 'Company' or `us' or 'we' refer to the owners of the Platform. The term 'you' or 'User' refers to the user or viewer of our Platform.&nbsp;</p>
            <p dir="ltr">This Privacy Policy applies to information the Company collected when the User uses the Platform and Services of the Company. The Company is committed to ensure that any use or sharing of your information with anyone shall be one only in accordance with the procedures described in this Privacy Policy with a view to offer an enriching and holistic internet experience to its Users. Some of the Services on the Platform are offered for free while other Services may be available on&nbsp; subscription or the purchase of an access credential. While you may visit the Platform without registration or subscription, you may need registration/subscription to access some areas and avail some Services.&nbsp;</p>
            <p dir="ltr">Before you access this Platform or submit any information to the Platform, please read this Privacy Policy for an explanation of how we will treat your personal information. By using any part of the Platform, you consent to the collection, use, disclosure and transfer of your personal information and financial information, for the purposes outlined in this Privacy Policy and to the collection, processing and maintenance of such information. If you do not agree to this Privacy Policy, please do not use the Platform. Your use of any part of the Platform indicates your acceptance of this Privacy Policy and of the collection, use, disclosure and transfer of your personal and financial information in accordance with this Privacy Policy. While you have the option not to provide us with certain information or withdraw consent to collect certain information, kindly note that in such an event, you may not be able to take full advantage of the entire scope of features and services offered to you and we reserve the right not to provide you our Services.</p>

            <p dir="ltr">Terms not defined herein will have the meaning ascribed to them in our Terms of Use (&ldquo;Terms of Use&rdquo;), as available at [<a href="https://vusport.com/terms-conditions">https://vusport.com/terms-conditions</a>]. This Policy shall be deemed and construed to be a part of the Terms of Use.</p>

            <p dir="ltr">Please also note that the Privacy Policy will also apply to information of Users supplied by other Users. In case of information of Users supplied by other Users, the User providing the information confirms and warrants that the User has the authority to provide the necessary User Information</p>

            <p dir="ltr"><strong>Purpose and Usage:</strong></p>

            <p dir="ltr">To register for the Platform, Users would be required to provide certain information for the registration process, which may include, but not be limited to:</p>

            <p dir="ltr">1. Username</p>
            <p dir="ltr">2. Password</p>
            <p dir="ltr">3. Email address</p>
            <p dir="ltr">4. Date of birth</p>
            <p dir="ltr">5. Mobile number&nbsp;</p>

            <p dir="ltr">In the course of providing you with access to the features and services offered through the Platform and to verify your identity, you may be required to furnish additional information.</p>

            <p dir="ltr">Upon installation/ access of the Platform by you, the Company may collect your device information such as its operating system, its hardware and software versions, available storage space, and the names of the applications on it.</p>

            <p dir="ltr">The User Information enables us to improve the Platform and provide you the most user-friendly experience. The Company may also share such information with affiliates and third parties in limited circumstances, including for the purpose of providing Services requested by the User complying with legal process, preventing fraud or imminent harm, ensuring the security of our network and services as well as for marketing and promotional purposes.&nbsp;</p>

            <p dir="ltr">Users are entitled to request the Company to delete their accounts and their personal information by sending a written request to <a href="mailto:helpdesk@vusport.com">helpdesk@vusport.com</a>. We may ask you to provide us further information to enable us to comply with your request.</p>

            <p dir="ltr">When you request deletion of your data, we follow a deletion process that ensures that your data is safely and completely removed from our servers or retained only in anonymised form. We try to ensure that our services protect information from accidental or malicious deletion. Because of this, there may be delays between when you request deletion and when copies are deleted from our active and backup systems</p>
            <p dir="ltr"><strong>Collection of Information</strong></p>
            <p dir="ltr">The Company collects two basic types of information from a User i.e. personal information, which is any information that exclusively identifies a User (e.g., the User&rsquo;s name, email address, telephone number, postal address amongst others) and non-personal information, which is information that does not exclusively identify a User but is related to you nonetheless such as, information about a User&rsquo;s interests, demographics and use of the sites. The Company may also receive personal information regarding a User as shared by third parties who maybe our clients or otherwise avail service from the Company. Please note that in such instances the Company is acting on the instructions provided by such client or user and the Company does not separately verify the right of the client or user to provide User Information to the Company. In case you are not the intended recipient of the services and Products, you should contact the Company and at the earliest.</p>

            <p dir="ltr">Towards the provision of the services by the Company and the use of the Products, the Company shall in addition to the information described above, collect the following information from a User (&ldquo;User Information&rdquo;):&nbsp;</p>

            <div dir="ltr" align="left">
                <table><colgroup><col width="180" /><col width="435" /></colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <p dir="ltr">Location</p>
                            </td>
                            <td>
                                <p dir="ltr">Information about the location of the User&rsquo;s device used to access the services from the Company. This is used for the provision of the services, pricing related information and other matters.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p dir="ltr">Access information&nbsp;</p>
                            </td>
                            <td>
                                <p dir="ltr">Passwords and other details required to access or use the services provided by the Company.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p dir="ltr">Personal information&nbsp;</p>
                            </td>
                            <td>
                                <p dir="ltr">Information about the name, email address, contact information and details of official documents. This is used for the provision of the services and for maintenance of records for audit purposes.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p dir="ltr">Transaction information</p>
                            </td>
                            <td>
                                <p dir="ltr">Transaction related information for transactions conducted by Users through the Services. Transaction information includes price and any associated or supporting data related to the purchase. Transaction information is used to provide the Services, facilitate the Transactions, provide support, and to operate, maintain and improve the Services.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p dir="ltr">Billing Information</p>
                            </td>
                            <td>
                                <p dir="ltr">To the extent permitted and required under applicable laws, billing and payment information of Users which may include a subscription number, an account number, credit/ debit card number, expiration date, billing zip code.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p dir="ltr">Automated collection&nbsp;</p>
                            </td>
                            <td>
                                <p dir="ltr">Details of the User&rsquo;s device, computer and device information, activity on the website, activity on the website and other similar information. The Company collects this information for analytics, improvement of the services and for review purposes.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p dir="ltr">Others</p>
                            </td>
                            <td>
                                <p dir="ltr">As may be explicitly required &amp; requested from time to time or as informed from time to tim.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p dir="ltr"><strong>Disclosure/Sharing:</strong></p>

            <p dir="ltr">The Company may also share User Information and data concerning usage of the Platform with third party service providers engaged by the Company, for the purpose of data analytics or other similar purposes, for the purpose of storage, improving the Services and helping the Company serve you better.&nbsp;</p>

            <p dir="ltr">Where we propose to use your personal information for any other uses, we will ensure that we notify you first. You will also be given the opportunity to withhold or withdraw your consent for your use other than as listed above.&nbsp;</p>

            <p dir="ltr">By using the Platform, you hereby expressly agree and grant consent to the collection, use and storage of this information by the Company. The Company reserves the right to share, disclose and transfer information collected hereunder with its own affiliates. In the event the Company sells or transfers all or a portion of its business assets, consumer information may be one of the business assets that are shared, disclosed or transferred as part of the transaction. You hereby expressly grant consent and permission to the Company for disclosure and transfer of information to such third parties. Further, the Company reserves the right to disclose personal and financial information as obligated by law, in response to duly authorized legal process, governmental requests and as necessary to protect the rights and interests of the Company.&nbsp;</p>

            <p dir="ltr"><strong>Children's Privacy:</strong></p>

            <p dir="ltr">We do not knowingly collect or solicit any information from persons below the age of 18 (eighteen) years (&ldquo;Minors&rdquo;) unless agreed and accepted by such Minor's parent or legal guardian. Further, we do not knowingly allow Minors to avail our Services without permission of their parent or legal guardian. In the event we learn that we have collected personal information from a Minor without the knowledge or consent of their parent or legal guardian, we will delete that information as quickly as possible. If you believe that we might have any information from a Minor without the knowledge or consent of their parent or legal guardian, please contact us at <a href="about:blank">helpdesk@vusport.com</a>. It is clarified that we will require and collect information regarding Minors for the purposes of allowing the Minors access to certain Services.</p>

            <p dir="ltr"><strong>Use of Cookies:&nbsp;</strong></p>

            <p dir="ltr">To improve the effectiveness and usability of the Platform for our Users, we use &ldquo;cookies&rdquo;, or such similar electronic tools to collect information by assigning each visitor a unique random number as a User Identification (User ID) to understand the User's individual interests using the identified computer. Unless the User voluntarily identifies himself/herself (e.g., through registration), the Company has no way of knowing who the User is, even if we assign a cookie to the User's computer. The only personal/financial information a cookie can contain is information supplied by the User. A cookie cannot read data off the User's hard drive. The Company's advertisers may also assign their own cookies to the User's browser (if the User clicks on their ad banners), a process that the Company does not control and therefore does not bear responsibility for.</p>

            <p dir="ltr">Most browsers allow you to control cookies, including whether or not to accept them and how to remove them. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, but please note that if you choose to erase or block your cookies, you may need to re-enter certain information to gain access to certain parts of the Platform.</p>

            <p dir="ltr">The Company's web servers automatically collect limited information about User's computer's connection to the internet, including User's IP address, when the User visits the Platform. (User's IP address is a number that lets computers attached to the internet know where to send data to the User -- such as the web pages viewed by the User). The User's IP address does not identify the User personally.</p>

            <p dir="ltr">The Company uses this information to deliver its web pages to users upon request, to tailor its Platform to the interests of its users, to measure traffic within the Platform, to evaluate the effectiveness of our site, analyse trends, see what difficulties our visitors may experience in accessing our site, administer the platform and let advertisers know the geographic locations from where the Company's visitors come.</p>

            <p dir="ltr">Our use of cookies and other tracking technologies allows us to improve our Platform and consequently your web experience. We may also analyze information that does not include personal information for trends and statistics.&nbsp;</p>

            <p dir="ltr"><strong>Links:&nbsp;</strong></p>

            <p dir="ltr">The Platform may include links to other websites. Such websites are governed by their respective privacy policies, which are beyond the Company's control. Once the User leaves the Company's servers (the User can tell where he/she is by checking the URL in the location bar on the User's browser), use of any User Information is governed by the privacy policy of the operator of the site which the User is visiting. That policy may differ from the Company's own. If the User can't find the privacy policy of any of these sites via a link from the site's homepage, the User may contact the site directly for more information. The Company is not responsible for the privacy practices or the content of such websites.</p>

            <p dir="ltr"><strong>Security Procedures:</strong></p>

            <p dir="ltr">All information gathered on the Platform is securely stored within the Company&rsquo;s database in accordance with applicable laws. Storage and transfer of User Information is carried out in compliance with all applicable laws, including without limitation, the Information Technology Act, 2000 and the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. Please note that the Services have reasonable security measures, including as mentioned in Information Technology Act, 2000 in place to protect the loss or misuse of the User Information. This database is stored on servers secured behind a firewall; access to such servers being password-protected and strictly limited based on need-to-know basis. However, we understand that as effective as our security measures are, no security system is impenetrable. Thus, we cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. The Company cannot guarantee, give no warranties, and shall not be liable for breach of the security of User Information through the services of the Company due to malicious attacks, errors, commission or omissions not wilfully initiated by the Company and any transmission is at the risk of the User. Further, any information you include in a posting to the discussion areas will be available to anyone with Internet access. By using the Platform, you understand and agree that your information may be used in or transferred to countries other than India.</p>

            <p dir="ltr">The Company also believes that the internet is an ever-evolving medium. We may periodically review from time to time and change our Privacy Policy to incorporate such future changes as may be considered appropriate, without any notice to you. Our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be. Any changes to our Privacy Policy will be posted on this page, so you are always aware of what information we collect, how we use it, how we store it and under what circumstances we disclose it.&nbsp;</p>

            <p dir="ltr"><strong>Advertising:</strong></p>

            <p dir="ltr">When the Company presents information to its online advertisers to help them understand our audience and confirm the value of advertising on the Platform, it is usually in the form of aggregated statistics on traffic to various pages within our site. When you register with the Company, we contact you from time to time about updating your content to provide features which we believe may benefit you.</p>

            <p dir="ltr">Several deceptive emails, websites, blogs etc. claiming to be from or associated with the Company may or are circulating on the Internet. These emails, websites, blogs etc. often include our logo, photos, links, content or other information. Some emails, websites, blogs etc. call the user to provide login name, password etc. or that the user has won a prize/ gift or provide a method to commit an illegal/ unauthorized act or deed or request detailed personal information or a payment of some kind. The sources and contents of these emails, websites, blogs etc. and accompanying materials are in no way associated with the Company. For your own protection, we strongly recommend not responding to emails or using websites, blogs etc.</p>

            <p dir="ltr">We may use the User Information provided by you to the Company, to contact you about the Services availed by you on the Platform or to inform you of our Services, to invite you to participate in surveys, opinion polls, etc., about our Services or otherwise (participation is always voluntary), in relation to any transaction entered by you on our Platform or to answer any of your queries where you have issued a query or requested information from us.</p>

            <p dir="ltr"><strong>Conditions of Use:&nbsp;</strong></p>

            <p dir="ltr">THE COMPANY DOES NOT WARRANT THAT THIS PLATFORM, IT'S SERVERS, OR EMAIL SENT BY US OR ON OUR BEHALF ARE VIRUS FREE. THE COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PLATFORM, INCLUDING, BUT NOT LIMITED TO COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL AND CONSEQUENTIAL DAMAGES, LOSS OF DATA, GOODWILL BUSINESS OPPORTUNITY, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT WILL THE COMPANY BE LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT IN EXCESS OF AN AMOUNT OF INR 100.&nbsp;</p>

            <p dir="ltr"><strong>Retention of Data:&nbsp;</strong></p>

            <p dir="ltr">Your User Information may be retained and may continue to be used until: (i) the relevant purposes for the use of your information described in this Privacy Policy are no longer applicable; and (ii) we are no longer required by applicable law, regulations, contractual obligations or legitimate business purposes to retain your personal information and the retention of your personal information is not required for the establishment, exercise or defense of any legal claim. After the expiry of this time period, the Company may either delete User&rsquo;s personal information or retain it in a form such that it does not identify the User personally.</p>

            <p dir="ltr"><strong>Applicable Law and Jurisdiction:</strong></p>

            <p dir="ltr">By visiting this Platform, you agree that the laws of the Republic of India without regard to its conflict of laws principles, govern this Privacy Policy and any dispute arising in respect hereof shall be subject to and governed by the dispute resolution process set out in the Terms of Use for the platform available at: <a href="https://vusport.com/terms-conditions%20%20">https://vusport.com/terms-conditions </a>&nbsp;</p>

            <p dir="ltr"><strong>Updating Information:</strong></p>

            <p dir="ltr">You will promptly notify the Company if there are any changes, updates or modifications to your information. Further, you may also review, update or modify your information and user preferences by logging into your Profile page on the Platform.</p>

            <p dir="ltr"><strong>Data breach and disclosure notification&nbsp;</strong></p>

            <p dir="ltr">In case of a data breach or an unauthorized disclosure of User Information which is likely to result in a high risk to the rights and freedoms of the User, unless the Company has appropriately mitigated the risk arising from such incident, the Company shall, within notify the affected Users of such breach with the details of the breach or incident, the consequences of such breach and the contact personnel of the Company in case of any queries by the User pertaining to such breach.&nbsp;&nbsp;</p>

            <p dir="ltr">In case any governmental or regulatory authority requires the Company to provide any User Information, unless specifically directors by the governmental or regulatory authority, the Company shall disclose to the User, the details of the request for the User Information.</p>

            <p dir="ltr"><strong>Communication policy</strong></p>

            <p dir="ltr">When the User sets up an account or receives any request pursuant to the provision of the Services, the User is deemed to have opted in to receive emails and messages from the Company and its service providers in connection with the provision of the Services as well as any marketing and promotional agencies. While you may opt out of promotional messages, you cannot opt out of receiving certain administrative, financial, technical and legal information from the Company in connection with the services.</p>

            <p dir="ltr"><strong>Contact Us:&nbsp;</strong></p>

            <p dir="ltr">Any questions or clarifications with respect to this Policy or any complaints, comments, concerns or feedback can be sent to the Company&rsquo;s Data Protection and Grievance Officer by writing at:&nbsp;</p>

            <p dir="ltr">Name: Rohit Saxena</p>
            <p dir="ltr">Email:<a href="about:blank">helpdesk@vusport.com</a></p>
            <p dir="ltr">Phone:-</p>
            <p dir="ltr">Address:D105-106, D Block, First Floor, Pioneer Urban Square, Sector 62, Gurgaon - 122102</p>


        </div>
    )
}

export default PrivacyPolicy
