import React, { useEffect } from 'react'
import '../scss/PlayerProfile.scss';
import { useLocation, useSearchParams } from 'react-router-dom';

const PlayerProfile = () => {

  //const [width, setWidth] = useState<number>(window.innerWidth);
  const [params] = useSearchParams()
  console.log(params);



  // function handleWindowSizeChange() {
  //     setWidth(window.innerWidth);
  // }
  // useEffect(() => {
  //     window.addEventListener('resize', handleWindowSizeChange);
  //     return () => {
  //         window.removeEventListener('resize', handleWindowSizeChange);
  //     }
  // }, []);

  // const isMobile = width <= 768;

  useEffect(() => {
    window.opta_settings = {
      subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
      language: 'en_GB',
      timezone: 'Europe/London',
      link_callback: function (params) {
        console.log(params);
        //				return params.base_url;

      }
    };
  }, [])

  const location = useLocation();
  const playerStasWidget = Object.fromEntries(new URLSearchParams(location.search));

  return (

    <div className='PlayerProfile'>
      <opta-widget
        widget="player_profile" competition={params.get('competition')} season={params.get('season')} team={params.get('team')} player={playerStasWidget.player} template={playerStasWidget.template} show_images={playerStasWidget.show_images} show_country={playerStasWidget.show_country} show_flags={playerStasWidget.show_flags} date_format={playerStasWidget.date_format} height_units={playerStasWidget.height_units} weight_units={playerStasWidget.weight_units} player_naming={playerStasWidget.player_naming} show_logo={playerStasWidget.show_logo} show_title={playerStasWidget.show_title} breakpoints={playerStasWidget.breakpoints} sport={playerStasWidget.sport}></opta-widget>

      <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
    </div>
  )
}

export default PlayerProfile
