import React from "react";
import "../styles/loader.css"
import { Outlet, Link, createSearchParams, useNavigate, Navigate } from "react-router-dom";



const standingsWid = {
  widget: "standings",
  competition: "4",
  season: "2017",
  match: "958084",
  team: "360",
  template: "form",
  live: "false",
  form: "6",
  default_nav: "1",
  side: "combined",
  data_detail: "brief",
  show_crests: "true",
  competition_naming: "full",
  team_naming: "abbr",
  team_link: "https://test.vusport.com/standings?widget=standings&competition=5&season=2021&match=2282406&team=14&template=normal&live=false&default_nav=1&side=combined&data_detail=default&show_key=false&show_crests=false&points_in_first_column=false&lose_before_draw=false&show_form=6&competition_naming=full&team_naming=full&date_format=dddd D MMMM YYYY&sorting=false&show_live=false&show_relegation_average=false&show_logo=true&show_title=true&breakpoints=1000&sport=football",
  date_format: "dddd D MMMM YYYY",
  show_live: "false",
  show_logo: "true",
  show_title: "true",
  breakpoints: "300",
  sport: "football"
}

const commentryWid = {
  widget: "commentary",
  competition: 4,
  season: 2017,
  match: 958084,
  template: "normal",
  live: "false",
  data_type: "auto",
  order_by: "time_descending",
  show_event_icons: "true",
  show_minor_events: "true",
  fixed_height_comments: 0,
  show_live: "false",
  show_logo: "true",
  show_title: "false",
  breakpoints: 1000,
  sport: "football"
}

const fixturesWid = {
  widget: "fixtures",
  fixtures_type: "all",
  competition: 4,
  match_link: "https://test.vusport.com/matchpreview",
  season: 2017,
  team: 360,
  template: "strip",
  live: "false",
  show_venue: "false",
  match_status: "all",
  grouping: "date",
  show_grouping: "true",
  navigation: "none",
  default_nav: 1,
  start_on_current: "true", sub_grouping: "date", show_subgrouping: "true", order_by: "date_ascending", show_crests: "true", date_format: "dddd D MMMM YYYY", time_format: "HH:mm", month_date_format: "MMMM", competition_naming: "full", team_naming: "full", pre_match: "false", show_live: "false", show_logo: "true", show_title: "false", breakpoints: 1000, sport: "football"
}

const matchPreviewWid = {
  widget: "match_preview", competition: "4", season: "2017", match: "958084", template: "normal", navigation: "tabs_more", default_nav: "1", show_match_header: "false", show_crests: "false", show_competition_name: "false", show_venue: "false", show_date: "false", date_format: "dddd D MMMM YYYY", time_format: "HH:mm", show_form: "6", show_fixtures: "0", show_previous_meetings: "0", show_top_scorers: "0", show_tied_scorers: "true", show_images: "false", competition_naming: "full", team_naming: "full", player_naming: "full", team_link: "https://test.vusport.com/standings?widget=standings&competition=5&season=2021&match=2282406&team=14&template=normal&live=false&default_nav=1&side=combined&data_detail=default&show_key=false&show_crests=false&points_in_first_column=false&lose_before_draw=false&show_form=6&competition_naming=full&team_naming=full&date_format=dddd D MMMM YYYY&sorting=false&show_live=false&show_relegation_average=false&show_logo=true&show_title=true&breakpoints=1000&sport=football", show_logo: "true", show_title: "false", breakpoints: "1500", sport: "football"
}


const matchSummaryWid = {
  widget: "match_summary", competition: "4", season: "2017", match: "958084", template: "normal", live: "false", show_match_header: "false", show_score: "true", show_attendance: "true", show_date: "true", date_format: "dddd D MMMM YYYY HH:mm", show_cards: "all", show_crests: "false", show_team_formation: "true", show_goals: "true", show_goals_combined: "false", show_penalties_missed: "true", show_halftime_score: "false", show_referee: "true", show_subs: "true", show_venue: "true", show_shootouts: "true", show_tooltips: "false", show_images: "false", show_competition_name: "true", competition_naming: "full", team_naming: "full", player_naming: "full", show_live: "false", show_logo: "true", show_title: "false", breakpoints: "1000", sport: "football"
}

const formationsWid = {
  widget: "starting_formations", competition: "4", season: "2017", match: "958084", template: "normal", orientation: "horizontal", show_match_header: "false", show_team_sheets: "false", show_competition_name: "true", show_crests: "false", show_pitch_crests: "false", show_images: "false", show_subs: "true", side: "both", player_naming: "full", team_naming: "full", show_logo: "true", show_title: "false", breakpoints: "1000", sport: "football"
}

const seasonPlayerStatsWid = {
  widget: "season_player_stats", competition: "4", season: "2017", team: "360", player: "39194", template: "normal", navigation: "tabs_more", default_nav: "1", show_split_stats: "false", round_navigation: "tabs", show_general: "true", show_distribution: "true", show_attack: "true", show_defence: "true", show_discipline: "true", show_goalkeeping: "true", team_naming: "full", player_naming: "full", show_logo: "true", show_title: "false", breakpoints: "1500", sport: "football"
}

const timelineWid = {
  widget: "match_summary", competition: "4", season: "2017", match: "958084", template: "time_ordered", live: "false", show_match_header: "true", show_score: "true", show_attendance: "false", show_date: "false", date_format: "dddd D MMMM YYYY HH:mm", show_cards: "all", show_crests: "true", show_team_formation: "true", show_goals: "true", show_goals_combined: "true", show_penalties_missed: "true", show_halftime_score: "true", show_referee: "false", show_subs: "true", show_venue: "false", order_by: "time_descending", show_shootouts: "true", show_tooltips: "true", show_images: "true", show_competition_name: "true", competition_naming: "full", team_naming: "full", player_naming: "full", show_live: "false", show_logo: "true", show_title: "false", breakpoints: "1500", sport: "football"
}

const teamStatsWid = {
  widget: "matchstats_pro", competition: "4", season: "2017", match: "958084", template: "team_graphs", live: "false", navigation: "dropdown", default_nav: "1", show_match_header: "true", show_score: "true", show_halftime_score: "true", show_crests: "true", show_competition_name: "true", graph_style: "full", show_date: "true", date_format: "dddd D MMMM YYYY", show_timecontrols: "true", show_timecontrols_buttons: "true", show_timecontrols_bar: "true", extended_content: "true", rounding: "1", competition_naming: "full", team_naming: "full", show_live: "false", show_logo: "true", show_title: "false", breakpoints: "1500", sport: "football"
}

const matchDetailWid = {
  widget: "timeline", competition: "4", season: "2017", match: "958084", template: "normal", live: "false", orientation: "vertical", vertical_timebar_height: "260", group_substitutions: "true", show_match_header: "false", show_score: "true", show_crests: "true", show_assists: "all", show_team_formation: "true", show_clock: "true", show_period_dividers: "true", show_halftime_score: "true", show_date: "true", date_format: "dddd D MMMM YYYY", show_attendance: "true", show_images: "true", show_referee: "true", show_venue: "true", show_cards: "all", show_goals: "true", show_penalties_missed: "true", show_subs: "true", show_goals_combined: "true", show_shootouts: "true", show_competition_name: "true", competition_naming: "full", team_naming: "full", player_naming: "full", show_live: "false", show_logo: "true", show_title: "false", breakpoints: "100", sport: "football"
}

const playerProfileWid = {
  widget: "player_profile", competition: "4", season: "2017", team: "360", player: "154976", template: "normal", show_images: "true", show_country: "true", show_flags: "true", date_format: "D MMMM YYYY", height_units: "m", weight_units: "kg", player_naming: "full", show_logo: "true", show_title: "true", breakpoints: "1500", sport: "football"
}

const playerPagewid = {
  competition: "4", season: "2017", team: "360", player: "39194", template: "normal", player_naming: "full", show_logo: "true", breakpoints: "2500", sport: "football"
}

const seasonTeamStatsWid = {
  competition: "4", season: "2022", team: "632", template: "normal", navigation: "dropdown", default_nav: "1", show_general: "true", show_distribution: "true", show_attack: "true", show_defence: "true", show_discipline: "true", team_naming: "full", show_logo: "true", show_title: "false", breakpoints: "1500", sport: "football"
}

const teamComparisonWid = {
  widget: "team_compare", fixtures_type: "all", competition: "8,8", season: "2022,2022", team: "43,1", template: "dual", navigation: "tabs_more", default_nav: "1", show_selects: "false", show_crests: "true", show_graphs: "false", graph_style: "full", competition_naming: "full", team_naming: "full", show_logo: "true", show_title: "false", breakpoints: "400", sport: "football"
}

const leaguePlayerStatsWid = {
  widget: "player_ranking",
  competition: "4",
  season: "2022",
  template: "normal",
  navigation: "dropdown",
  show_crests: "true",
  show_images: "true",
  show_ranks: "true",
  show_appearances: "true",
  visible_categories: "goals,assists,cards_yellow,cards_red,saves",
  limit: "10",
  hide_zeroes: "true",
  show_team_names: "true",
  team_naming: "full",
  player_naming: "full",
  show_logo: "true",
  show_title: "false",
  breakpoints: "1500",
  sport: "football"
}

const Layout = () => {
  const navigate = useNavigate();


  const handleTeamStats = () => {
    navigate({
      pathname: "/teamstats",
      search: createSearchParams(teamStatsWid).toString()
    });
  }

  const handleTeamComparison = () => {
    navigate({
      pathname: "/teamComparison",
      search: createSearchParams(teamComparisonWid).toString()
    });
  }


  const handleLeaguePlayerStats = () => {
    navigate({
      pathname: "/leagueplayerstats",
      search: createSearchParams(leaguePlayerStatsWid).toString()
    });
  }


  const handleSeasonTeamStats = () => {
    navigate({
      pathname: "/seasonteamstats",
      search: createSearchParams(seasonTeamStatsWid).toString()
    });
  }


  const handlePlayerPage = () => {
    navigate({
      pathname: "/playerpage",
      search: createSearchParams(playerPagewid).toString()
    });
  }


  const handleMatchDetail = () => {
    navigate({
      pathname: "/matchdetail",
      search: createSearchParams(matchDetailWid).toString()
    });
  }

  const handleTimeline = () => {
    navigate({
      pathname: "/timeline",
      search: createSearchParams(timelineWid).toString()
    });
  }

  const handleStandings = () => {
    navigate({
      pathname: "/standings",
      search: createSearchParams(standingsWid).toString()
    });
  }

  const handleCommentry = () => {
    navigate({
      pathname: "/commentry",
      search: createSearchParams(commentryWid).toString()
    });
  }

  const handleFixtures = () => {
    navigate({
      pathname: "/fixtures",
      search: createSearchParams(fixturesWid).toString()
    });
  }

  const handleMatchPreview = () => {
    navigate({
      pathname: "/matchpreview",
      search: createSearchParams(matchPreviewWid).toString()
    });
  }

  const handleMatchSummary = () => {
    navigate({
      pathname: "/matchsummary",
      search: createSearchParams(matchSummaryWid).toString()
    });
  }

  const handleFormations = () => {
    navigate({
      pathname: "/formations",
      search: createSearchParams(formationsWid).toString()
    });
  }

  const handleSeasonPlayerStats = () => {
    navigate({
      pathname: "/seasonplayerstats",
      search: createSearchParams(seasonPlayerStatsWid).toString()
    });


  }

  const handlePlayerProfile = () => {
    navigate({
      pathname: "/playerprofile",
      search: createSearchParams(playerProfileWid).toString()
    });
  }

  React.useLayoutEffect(() => {
    navigate.setOptions?.({ headerShown: false });
  }, [navigate]);

  return (

    <>
      <div>
        <ul>
          <li onClick={handleStandings}>
            Standings
          </li>
          <li onClick={handleFixtures} >
            Fixtures
          </li>
          <li onClick={handleMatchPreview}>
            Match Preview
          </li>
          <li onClick={handleMatchSummary}>
            Match Summary
          </li>
          <li onClick={handleFormations}>
            Starting Formations
          </li>
          <li onClick={handleCommentry}>
            Commentry
          </li>
          <li onClick={handleTimeline}>
            Timeline
          </li>
          <li onClick={handleTeamStats}>
            Team Stats
          </li>
          <li onClick={handleMatchDetail}>
            Match Details
          </li>
          <li onClick={handlePlayerProfile}>
            Player Profile
          </li>
          <li onClick={handleSeasonPlayerStats}>
            Season Player Stats
          </li>

          <li onClick={handlePlayerPage}>
            Player Page
          </li>

          <li onClick={handleSeasonTeamStats}>
            Season Team Stats
          </li>

          <li onClick={handleLeaguePlayerStats}>
            League Player Stats
          </li>
          <li onClick={handleTeamComparison}>
            Team Comparison
          </li>

          <li>
            <Link to="/aboutus">About Us</Link>
          </li>
          <li>
            <Link to="/privacypolicy">Privacy Policy</Link>
          </li>

          <li>
            <Link to="/contactus">Contact Us</Link>
          </li>

          <li>
            <Link to="/faq">FAQs</Link>
          </li>

          <li>
            <Link to="/tnc">Terms and Conditions</Link>
          </li>

          <li>
            <Link to="/faqaccordian">FAQ Accordian</Link>
          </li>

        </ul>
      </div>
      <Outlet />
    </>
  )
};

export default Layout;