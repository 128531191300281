import React from 'react'
import '../../scss/TnC.scss'

const TermsAndConditions = () => {
    return (
        <div className='TnC' style={{ padding: '0px 20px 20px 20px', maxWidth: '1100px', margin: 'auto', }}>
            <p dir="ltr"><span>The website located at </span><a href="https://vusport.com"><span>https://vusport.com</span></a><span> and the mobile application VUSport (the &ldquo;</span><span>Platform</span><span>&rdquo;) are owned and operated by the Super Six Sports Gaming Private Limited (&ldquo;</span><span>Company</span><span>&rdquo;). Through the Platform, the Company provides users with, information regarding detailed match previews, fantasy research, reviews informative articles and sports analysis (&ldquo;</span><span>Content Services</span><span>&rdquo;) and access to live streaming of matches and tournaments of certain sports (&ldquo;</span><span>Streaming Services</span><span>&rdquo;) (collectively, the &ldquo;</span><span>Services</span><span>&rdquo;).&nbsp;</span></p>

            <p dir="ltr"><span>The terms &lsquo;Company&rsquo; or &lsquo;us&rsquo; or &lsquo;we&rsquo; refer to the owners of the Platform. The term &lsquo;you&rsquo; or &lsquo;your&rsquo; refers to the user or viewer of our Platform.&nbsp;</span></p>

            <p dir="ltr"><span>By browsing, viewing, using the Platform, including, without limitation, by availing Services on or under the Platform, you consent to and agree to comply with these terms and conditions of use (&ldquo;</span><span>Terms</span><span>&rdquo; or &ldquo;</span><span>Terms of Use</span><span>&rdquo;), which together with our Privacy Policy govern the Company's relationship with you in relation to the Platform. The Company provides the Services subject to the notices, terms and conditions set forth in these Terms. In addition, when you subscribe/purchase access to the Services, you will be subject to the rules, guidelines, policies, terms, and conditions applicable to such Services, which will be deemed to be incorporated into these Terms by reference. If you disagree with any part of these Terms, you may discontinue using our Platform immediately. The Company reserves the right to change or modify the Platform, the contents thereof and these Terms of Use at any time without any prior intimation to you. All modifications will be posted on the Platform and will become effective immediately upon such posting to the Platform. It is your responsibility to check this page periodically for changes.</span><span>Following the posting of any changes, your continued use of the Platform constitutes acceptance of such revised Terms of Use.&nbsp;</span></p>

            <p dir="ltr"><span>ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS IN THESE TERMS, AS MODIFIED FROM TIME TO TIME. THEREFORE, PLEASE READ THESE TERMS CAREFULLY BEFORE PROCEEDING.&nbsp;</span></p>

            <p dir="ltr"><span><strong>PART A: General Terms</strong>&nbsp;</span></p>

            <p dir="ltr"><strong>Registration:</strong></p>

            <p dir="ltr"><span>While you may visit the Platform without registration or subscription, some Services on the Platform may be accessible by registration and/or subscription only. You may subscribe to the Services on the Platform only after registering with the Platform.</span></p>

            <p dir="ltr"><span>The information sought at the time of registration may include your username, email address, password, date of birth and mobile number. You warrant that the information provided by you during the registration process is true, accurate, current, authorized, and complete in all aspect. You are responsible for maintaining the confidentiality of your login credentials such as account and password and for restricting access to your computer or mobile phone, any other device and agree to accept responsibility for all activities that occur under your account. All actions performed by any person using your account and password shall be deemed to have been committed by you and you shall be liable for the same. The Company reserves the right to cancel your access to a Service at any time, if it is found that you have been sharing the password with any unauthorised user. You shall notify the Company immediately, if you have noticed any breach or unauthorized use of your account.</span></p>
            <p dir="ltr"><strong>Platform Content</strong></p>

            <p dir="ltr"><span>Except as specifically provided with respect to Contributed Content, (defined below) all materials, including all documents, illustrations, statements, photographs, products, images, artwork, designs, text, graphics, logos, button icons, images, audio and video clips, audio and video feed, and software (collectively, &ldquo;Content&rdquo;) appearing on the Platform, are protected under applicable laws relating copyright, trademark and other intellectual property rights that are owned by the Company or by other parties that have licensed their material to the Company. You acquire no rights or licenses in or to the Content, except for the limited license granted to you herein. Except where otherwise agreed in writing with the Company, all Content on the Platform including in respect of the Services is solely for your personal, non-commercial use provided that you maintain all copyright and other notices contained therein. You may not copy Content from the Platform, or otherwise modify, obscure or delete any copyright or other proprietary notices on the Platform. No right, title or interest in any materials or software, if applicable, on the Platform is transferred to you from your use of the Platform. You may not and shall not assist any other person to, copy, reproduce, publish, transmit, distribute, display, modify, create derivative works from, sell or participate in any sale of, or exploit in any way, in whole or in part, any of the Content, the Platform, or any related material without the prior written authorization of the Company.</span></p>

            <p dir="ltr"><span>You agree not to rearrange or modify the Content available through the Platform. You further agree not to display, post, frame, or scrape such Content for use on another website, app, blog, product or service, except as otherwise expressly permitted by these Terms. In addition, you agree not to create any derivative work based on or containing such Content. The framing or scraping of or in-line linking to the Services or any Content contained thereon and/or the use of webcrawler, spidering or other automated means to access, copy, index, process and/or store any Content made available on or through the Services other than as expressly authorized by us is prohibited. You further agree not to hack into, interfere with, disrupt, disable, over-burden or otherwise impair the proper working of the Platform or its servers, which shall include but is not limited to denial-of-service attacks, spoof attacks, session hacking, sniffing, tampering, reverse engineering or reprogramming. You further agree to abide by exclusionary protocols (e.g., Robots.txt, Automated Content Access Protocol (ACAP), etc.) that may be used in connection with the Services. You may not access parts of the Services to which you are not authorized, or attempt to circumvent any restrictions imposed on your use or access of the Services.</span></p>

            <p dir="ltr"><span>You may not create apps, extensions, or other products and services that use our Content without our permission. You may not aggregate or otherwise use our Content in a manner that could reasonably serve as a substitute for a subscription with the Platform or purchasing Services offered on the Platform. You may not access or view the Services with the use of any scripts, extensions, or programs that alter the way the Services are displayed, rendered, or transmitted to you without our written consent. You agree not to use the Services for any unlawful purpose, and to comply with all applicable laws and regulations, including without limitation, laws relating copyright and trademark. We reserve the right to terminate or restrict your access to the Platform if, in our opinion, your use of the Services violate any laws, regulations or rulings, infringe upon another person's rights or violate these Terms.</span></p>

            <p dir="ltr"><span>You shall not post any content or comments that are defamatory, inflammatory, obscene, derogatory, or in any manner illegal and comply fully with the norms set out below under 'Contributed Content'. Any indulgence in personal attacks, name calling or inciting hatred against any community shall be taken down immediately when brought to our notice. You shall at all times comply with these Terms and applicable laws. We reserve the right to take down any content or comment if it does not comply with the Terms and/or applicable law.</span></p>

            <p dir="ltr"><span>You shall note that ours is merely an intermediary platform which provides the technology/platform for making comments, posting content and related interactions. It neither monitors, nor controls the comments and content posted by users. If any content or comment violates your rights in any manner or is not in compliance with policy, terms, guidelines, or the law, you may send us a take-down request by sending an email to </span><a href="mailto:helpdesk@vusport.com"><span>helpdesk@vusport.com</span></a><span> with the subject comment/content grievance.</span></p>

            <p dir="ltr"><span>We neither endorse nor take any responsibility or liability for any comments or content posted on our Platform. All express/implied liability in this regard is expressly disclaimed.</span></p>

            <p dir="ltr"><strong>Contributed Content&nbsp;</strong></p>

            <p dir="ltr"><span>The Platform may contain views/ideas/opinions (&ldquo;</span><span>Contributed Content</span><span>&rdquo;) expressed by independent authors/contributors/users/posters on various topics. Such views/ideas/opinions are solely those of the authors/contributors/users/posters and not endorsed in any manner by the Company. The Company shall not be responsible for or liable in any manner whatsoever for the views/ideas/opinions expressed by such authors/contributors/users/posters in any section of the Platform. Further, the accuracy of such statements has not been verified by the Company and the Company provides no warranties of the same. If you seek to rely on any representation of information contained on the Platform, any such reliance shall be at your own risk.</span></p>

            <p dir="ltr"><span>Additionally, you agree that any Contributed Content by you, may be used by the Company, including for creation of user profiles, that will include such Contributed Content, consistent with the Privacy Policy and Terms of Use as mentioned herein and you are not entitled to any payment or other compensation for such use.</span></p>

            <p dir="ltr"><span>In using the Platform, you agree not to (a) upload, download, post, email, or otherwise transmit any Contributed Content that is or may be construed to be (i) unlawful, harmful, threatening, abusive, vulgar, harassing, defamatory, obscene, pornographic, profane, indecent, inflammatory, libellous, tortious, hateful; (ii) racially, ethnically, socially, politically, legally, morally, religiously objectionable or otherwise objectionable; or (iii) invasive of any individual's rights including but not limited to rights of celebrity, privacy and intellectual property; (b) impersonate any individual or entity or falsely state or otherwise misrepresent your affiliation with any individual or entity; (c) upload, download, post, email or otherwise transmit any Contributed Content that infringes any patent, trademark, trade secret, copyright or other intellectual, proprietary right, privacy right or other right of any party under applicable laws; (d) upload, download, post, email or otherwise transmit any Contributed Content that may be or contain anything which is technically harmful (including, without limitation, a computer virus, malware, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data), interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (e) upload, download, post, email or otherwise transmit any Contributed Content that facilitates or promotes illegal activity; (f) upload, download, post, email or otherwise transmit any User Content that is contributed for commercial gain; and (g) disrupt or interfere with any other user's enjoyment of the Platform.</span></p>

            <p dir="ltr"><span>The determination of whether any Contributed Content violates these Terms, or is otherwise abusive illegal or disruptive, is subject to the sole determination of the Company.&nbsp;</span></p>

            <p dir="ltr"><span>The Company reserves the right to cease to provide you with the Services or with access to the Platform, prevent you from accessing the Platform and/or Services and terminate your subscription/access credential to a Service, with immediate effect and without notice and liability, for violating these Terms, applicable law, rules or regulations.</span></p>

            <p dir="ltr"><strong>Acceptable Use Policy</strong></p>

            <p dir="ltr"><span>While accessing the Platform or using any of the Services, you hereby agree that you will not:</span></p>

            <p dir="ltr"><span>(a) violate any applicable laws, regulation, or court order;</span></p>
            <p dir="ltr"><span>(b) not infringe, misappropriate, or otherwise use without necessary authorization, any intellectual property, privacy, publicity, moral, or other rights of any third party, or violate any law, regulation, or court order;</span></p>
            <p dir="ltr"><span>(c) take any action (even if requested by another) that is, or submit, post, share, or communicate anything that is, or that incites or encourages, action that is, explicitly or implicitly: illegal, abusive, harassing, threatening, hateful, racist, derogatory, harmful to any reputation, pornographic, indecent, profane, obscene, or otherwise objectionable (including nudity);</span></p>
            <p dir="ltr"><span>(d) send advertising or commercial communications, including spam, or any other unsolicited or unauthorized communications;</span></p>
            <p dir="ltr"><span>(e) engage in spidering or harvesting, or participate in the use of software, including spyware, designed to collect data from the Platform, including from any user of the Platform;</span></p>
            <p dir="ltr"><span>(f) transmit any virus, other computer instruction, or technological means intended to, or that may, disrupt, damage, or interfere with the use of computers or related systems;</span></p>
            <p dir="ltr"><span>(g) remove any proprietary notices or labels from the Services;</span></p>
            <p dir="ltr"><span>(h) stalk, harass, threaten, or harm any third party;</span></p>
            <p dir="ltr"><span>(i) impersonate any third party;</span></p>
            <p dir="ltr"><span>(j) participate in any fraudulent or illegal activity, including phishing, money laundering, or fraud;</span></p>
            <p dir="ltr"><span>(k) use any means to scrape or crawl any part of the Platform;</span></p>
            <p dir="ltr"><span>(I) attempt to circumvent any technological measure implemented by the Company, any of the Company's providers, or any other third party (including another user) to protect the Company, the Platform, or any other third party;</span></p>
            <p dir="ltr"><span>(m) access the Platform to obtain information to build a similar or competitive website, application, or service;</span></p>
            <p dir="ltr"><span>(n) attempt to decipher, decompile, disassemble, or reverse engineer any of the software or other underlying code used to provide the Platform; or</span></p>
            <p dir="ltr"><span>(o) advocate, encourage, or assist any third party in doing any of the foregoing.</span></p>
            <p dir="ltr"><span>(p) threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation;&nbsp;</span></p>
            <p dir="ltr"><span>(q) provide in-complete, inaccurate, untrue or unauthorized information while submitting any information to the Platform;</span></p>
            <p dir="ltr"><span>(r) interfere with the use of the Platform by others or interfere with the operation of the Platform.</span></p>
            <p dir="ltr"><strong>Warranty and Liability Disclaimer</strong></p>

            <p dir="ltr"><span>The Platform, the Content herein and the other materials and Services made available to you through the Platform, are provided by the Company in good faith on an &ldquo;as is&rdquo;, &ldquo;as available&rdquo; basis, without any warranties and the Services provided may not be uninterrupted or error-free. In particular, the Company does not guarantee the accuracy, timeliness, completeness, performance or fitness for a particular purpose of the Platform and the Services. Further, the Company makes no representations or warranties of any kind, express or implied, as to the operation of the Platform, the information, the Content or materials herein or hereon, and the Services. You acknowledge that any reliance upon any such opinion, advice, statement, memorandum, or information shall be at your sole risk. To the maximum extent permitted under applicable law, the Company disclaims all responsibility (whether direct, indirect, consequential or otherwise) with respect to the accuracy or otherwise of the information, Content or materials arising from the use of the Platform and warranties, whether express or implied, including the warranty of fitness for particular purpose and non-infringement. Without prejudice to the foregoing paragraph, the Company does not warrant that:</span></p>

            <p dir="ltr"><span>(a) the Platform and the Services will be constantly available, or available at all;&nbsp;</span></p>
            <p dir="ltr"><span>(b) the information on the Platform or is complete, true, accurate, reliable or not misleading; or&nbsp;</span></p>
            <p dir="ltr"><span>(c) the quality of any information, or other material that you obtain through the Platform will meet your expectations.</span></p>
            <p dir="ltr"><span>(d) the Platform; information, Content or materials included on or otherwise made available to you through the Platform; the servers hosting the Platform; or electronic communication sent from the Company are free of viruses or other harmful components. The Company utilises safeguards and industry best practices to protect the Platform from hackers, sniffers and malicious modification tools, however, it does not warrant that the Platform shall be completely safe from such attacks at any period of time.&nbsp;</span></p>

            <p dir="ltr"><span>The Company makes no guarantee to the resolution or quality of the Streaming Services content you will receive. All other warranties, whether legal, express or implied, including but not limited to any express or implied warranties of merchantability, of fitness for a particular usage or for the intended use thereof are expressly excluded in connection with the use of the Platform.</span></p>

            <p dir="ltr"><span>The Company is constantly endeavouring to improve the quality of Services provided to you. Due to this, the form and nature of Services provided may change from time to time without any prior notice to you. The Company reserves the right to introduce and initiate new features, functionalities, components to the Platform and/or Services and/or change, alter, modify, suspend, discontinue or remove the existing ones without any prior notice to you. Further, the Company is entitled to discontinue (either permanently or temporarily) one or more of the Services provided or terminate the Platform without any prior notice to you. The Company may also prescribe certain limits on the use of the Platform and/or Services or storage of Content at its sole discretion without any prior notice to you while at all times complying with its Privacy Policy.</span></p>

            <p dir="ltr"><span>To the extent permitted under law, the Company shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use the Platform or the Services, even if you have been advised of the possibility of such damages.</span></p>

            <p dir="ltr"><span>Notwithstanding anything to the contrary the Company, its successors, agents, assigns, and each of their directors, officers, employees, associates, agents, and representatives shall in no event be liable to you or any other person for:</span></p>

            <ol>
                <li dir="ltr">
                    <p dir="ltr"><span>any indirect, incidental, special, consequential, punitive or economic loss, expense or damage arising from or in connection with any access, use or the inability to access or use the Company&rsquo;s products/services and data/content or reliance on those, including without limitation, in case of the Services, the Platform, howsoever caused and regardless of the form of action (including tort or strict liability);</span></p>
                </li>
            </ol>

            <ol start="2">
                <li dir="ltr">
                    <p dir="ltr"><span>any downtime costs, loss of revenue or business opportunities, loss of profit, loss of anticipated savings or business, loss of data, loss of goodwill or loss of value of any equipment including software; and/or</span></p>
                </li>
            </ol>

            <ol start="3">
                <li dir="ltr">
                    <p dir="ltr"><span>any loss or damage arising as a result of a disclosure/sharing or hacking of the User&rsquo;s login details and password, and any improper usage, failure or malfunction of any computer or mobile phone, unavailability of an electricity connection, other telecommunications equipment, any other services or equipment necessary to access the Company&rsquo;s products and services, including without limitation, the Platform.</span></p>
                </li>
            </ol>

            <p dir="ltr"><strong>The Company shall not be liable for any inconvenience, loss, cost, damage or injury suffered by you arising from or caused by:</strong></p>

            <ol>
                <li dir="ltr">
                    <p dir="ltr"><span>act or omission of any third party including but not limited to any equipment or software providers, any service providers, any network providers (including but not limited to telecommunications providers, internet browser providers and internet access providers), or any agent or subcontractor of any of the foregoing;</span></p>
                </li>
            </ol>

            <ol start="2">
                <li dir="ltr">
                    <p dir="ltr"><span>the use of the Company&rsquo;s products and services, including without limitation, the Platform by third persons, whether authorised or unauthorised by you;</span></p>
                </li>
            </ol>

            <ol start="3">
                <li dir="ltr">
                    <p dir="ltr"><span>theft or loss of your computer, mobile phone, your data, your login details and password, hardware and/or equipment on which the Platform is accessed or operated;</span></p>
                </li>
            </ol>

            <ol start="4">
                <li dir="ltr">
                    <p dir="ltr"><span>you being deprived of the use of the Platform as a consequence of any act or omission by the Company for any reason including but not limited to compliance with any applicable laws and/or regulations and any instructions and/or directions given by any local or foreign regulatory body, government agency, statutory board, ministry, departments or other government bodies and/or its officials.</span></p>
                </li>
            </ol>

            <p dir="ltr"><span>NOTWITHSTANDING ANYTHING TO THE CONTRARY, IN NO EVENT, SHALL THBE COMPANY OR ANY OF ITS DIRECTORS, EMPLOYEES, AGENTS AND/OR PERSONNEL, BE LIABLE TO THE USER OR ANY PERSON FOR ANY DAMAGES, LIABILITIES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO: (I) THESE TERMS OF SERVICE, THE SERVICES, THE PLATFORM, OR ANY REFERENCE SITE, APP, PRODUCTS OR SERVICES; OR (II) THE USER&rsquo;S USE OR INABILITY TO USE THE SERVICES, THE PLATFORM, OR OTHER PRODUCTS OR SERVICES OF THE COMPANY OR ANY REFERENCE SITE; OR (III) ANY OTHER INTERACTIONS WITH THE COMPANY; HOWEVER CAUSED AND WHETHER ARISING IN CONTRACT, TORT INCLUDING NEGLIGENCE, WARRANTY OR OTHERWISE, BEYOND OR IN EXCESS INR 1,000, OR ANY LOWER AMOUNT AS MAY BE PERMITTED UNDER APPLICABLE LAWS, WHICHEVER IS LESS.</span></p>
            <p><span><span><br /><br /><br /></span></span></p>
            <p dir="ltr"><strong>Nothing on the Platform constitutes, or is meant to constitute, advice of any kind.</strong></p>
            <p dir="ltr"><span>&nbsp;</span></p>
            <p dir="ltr"><strong>Third Party Links</strong></p>

            <p dir="ltr"><span>Please note that the Platform may contain links provided by third parties (advertisers or otherwise). The Company is not responsible for any errors, omissions or representations on the Platform to the extent such information is updated or provided directly by the third parties. Additionally, any website or information accessed by or as a result of following such third-party links is at your sole risk. The content viewed through any third-party links is not controlled by or endorsed in any manner by the Company. The Company shall not be responsible for or liable in any manner whatsoever for the content provided through such third-party links. Further, the accuracy of the content provided through third party links has not been verified by the Company and we provide no warranties with respect to the same. Any information collected on websites visited through such third-party links is subject to the privacy policies of such third-party websites. You are requested to review the third-party&rsquo;s policies and practices carefully and make sure that you understand them. Complaints, concerns, or questions regarding third-party links should be directed to such third-party.</span></p>

            <p dir="ltr"><strong>Accuracy of Information</strong></p>

            <p dir="ltr"><span>The information presented on the Platform has been compiled by the Company from various sources including from external sources. No representation is made or warranty given as to the completeness or accuracy of such information. The Platform may contain typographical errors and incomplete or out of date information. The Company reserves the right to make changes or modifications to the Content, Contributed Content and information on the Platform, including without limitation, in order to meet the Company standards and regulations, or update such information at any time without notice, but the Company makes no commitment to correct or update this information. If you seek to rely on any representation of information contained on the Platform, any such reliance shall be at your own risk.</span></p>

            <p dir="ltr"><strong>Restriction on Age</strong></p>

            <p dir="ltr"><span>Users of the Platform are generally required to be of 18 years of age and above. Any user below 18 years of age will be required to seek permission or consent from their parents or legal guardians before furnishing data and registering on the Platform, or inter alia, using the Platform, or being a part, directly or indirectly, of any activity on the Platform. Using the Platform without consent from parent/s or legal guardian and consequent participation in any activity on the Platform is not permitted and such person may be denied access to the Platform at the sole and absolute discretion of the Company, whenever it comes to the knowledge of the Company.</span></p>

            <p dir="ltr"><strong>Waivers</strong></p>
            <p><strong>&nbsp;</strong></p>
            <p dir="ltr"><span>You hereby waive any rights, whether existing or acquired in future, that are attributable to you under any applicable law, including (without limitation) any law, regulation or order concerned with privacy or personality rights, in relation to the matters contemplated herein, without any claim for compensation.&nbsp;</span></p>

            <p dir="ltr"><strong>Indemnification</strong></p>

            <p dir="ltr"><span>You represent, warrant and covenant (a) that you will fulfil your obligations hereunder these Terms in good faith; (b) that no materials of any kind provided by you will (i) violate, plagiarize, or infringe upon the rights of any third party, including copyright, trademark, privacy or other personal or proprietary rights; or (ii) contain defamatory or otherwise unlawful material. You hereby indemnify, defend and hold harmless the Company and all of the Company's officers, directors, owners, agents, information providers, affiliates, licensors, licensees and third-party service providers (collectively, the &ldquo;</span><span>Indemnified Parties</span><span>&rdquo;) from and against any and all liability and costs, including, without limitation, reasonable legal fees, incurred by the Indemnified Parties in connection with any claim arising out of any breach by you of these Terms or the foregoing representations, warranties and covenants. You shall cooperate as fully as reasonably required in the defense of any such claim. The Company reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you.&nbsp;</span></p>

            <p dir="ltr"><strong>Termination</strong></p>

            <p dir="ltr"><span>The Company may immediately, without notice, deny you access to the Platform if, in the Company's opinion, you fail to comply with any or all of the Terms set forth herein. Ownership of Marks All trademarks, service marks and trade names on the Platform, are proprietary to the Company or their respective owners. You shall not use of any of these for any purpose whatsoever without the prior written authorization of the Company or the relevant owners.&nbsp;</span></p>

            <p dir="ltr"><strong>Infringement of Copyright</strong></p>

            <p dir="ltr"><span>In good faith, if you have reason to believe that any work copyrighted by you has been reproduced, embedded, or linked without any authorization on the Platform, in a manner that constitutes an infringement of your copyright under Indian law, please compile the following information and email to: </span><a href="mailto:helpdesk@vusport.com"><span>helpdesk@vusport.com</span></a><span>&nbsp;</span></p>
            <ul>
                <li dir="ltr">
                    <p dir="ltr"><span>A clear identification of the copyrighted work allegedly infringed;</span></p>
                </li>
                <li dir="ltr">
                    <p dir="ltr"><span>A clear identification of the allegedly infringing material on the Platform (with specific URL reference where available);</span></p>
                </li>
                <li dir="ltr">
                    <p dir="ltr"><span>Your contact details: name, address, email address and phone number;</span></p>
                </li>
                <li dir="ltr">
                    <p dir="ltr"><span>A statement that you believe, in good faith, that the use of the copyrighted material allegedly infringed on the Platform is not authorized by your agent or the law;</span></p>
                </li>
                <li dir="ltr">
                    <p dir="ltr"><span>A statement that the information provided in the notice is accurate, and under penalty of perjury, that the signatory is authorized to act on behalf of the owner of an exclusive copyright right that is allegedly infringed;</span></p>
                </li>
                <li dir="ltr">
                    <p dir="ltr"><span>Your signature or a signature of your authorized agent.&nbsp;</span></p>
                </li>
            </ul>

            <p dir="ltr"><strong>Applicable Law</strong></p>

            <p dir="ltr"><span>This Platform, including the Content, Contributed Content and information contained herein, and the Services provided on or under the Platform, shall be governed by the laws of India and the courts in Gurgaon, Haryana shall retain exclusive jurisdiction to entertain any proceedings in relation to any disputes arising out of the same. As such, the laws of India shall govern any disclaimers, legal notices, privacy policies of this Platform, and any transactions completed using this Platform.</span></p>

            <p dir="ltr"><strong>Assignment</strong></p>

            <p dir="ltr"><span>You may not assign your rights and obligations under these Terms without the prior written consent of the Company. The Company may assign its rights and obligations to a third party upon issuing written notification via an announcement on the Platform.&nbsp;</span></p>

            <p dir="ltr"><strong>Grievance Redressal Mechanism&nbsp;</strong></p>

            <p dir="ltr"><span>In case you have any complaints or grievance pertaining to (i) any Contributed Content that you believe violates these Terms (other than an infringement of Intellectual Property Rights) for the reporting mechanism for such claims), (ii) your access to the Platform or (iii) any Contributed Content which you believe is, prima facie, in the nature of any material which is obscene, defamatory towards you or any person on whose behalf you are making the complaint, or is in the nature of impersonation in an electronic form, including artificially morphed images of such individual, please share the same with us by writing to: </span><a href="mailto:grievanceofficer@vusport.com"><span>grievanceofficer@vusport.com</span></a></p>

            <p dir="ltr"><span>In your complaint or grievance, please include the following information: (i) your name and contact details: name, address, contact number and email address; (ii) your relation to the subject matter of the complaint, i.e. complainant or person acting on behalf of an affected person; (iii) the name and age of the person aggrieved or affected by the subject matter of the complaint, in case you are acting on behalf of such person and a statement, under penalty of perjury, that you are authorised to act on behalf of such person and to provide such person's personal information to us in relation to the complaint/grievance; (iv) description of the complaint or grievance with clear identification of the Contributed Content in relation to which such complaint or grievance is made; (v) a statement that you believe, in good faith, that the Contributed Content violates these Terms; and (vi) a statement that the information provided in the complaint or grievance is accurate.</span></p>

            <p dir="ltr"><span>The Company respects the Intellectual Property Rights of others. All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Platform belonging to any person (including User), entity or third party are recognized as proprietary to the respective owners. You are requested to send us a written notice/ intimation if you notice any act of infringement on the Platform, which must include the following information: (i) a clear identification of the copyrighted work allegedly infringed; (ii) a clear identification of the allegedly infringing material on the Platform; (iii) your contact details: name, address, e-mail address and phone number; (iv) a statement that you believe, in good faith, that the use of the copyrighted material allegedly infringed on the Platform is not authorized by your agent or the law; (v)&nbsp; a statement that the information provided in the notice is accurate, and under penalty of perjury, that the signatory is authorized to act on behalf of the owner of an exclusive copyright right that is allegedly infringed; and (vi) your signature or a signature of your authorized agent. The aforesaid notices can be sent to the Company by email at </span><a href="mailto:grievanceofficer@vusport.com"><span>grievanceofficer@vusport.com</span></a></p>

            <p dir="ltr"><span>On receiving such complaint, grievance or notice, the Company reserves the right to investigate and/or take such action as the Company may deem appropriate. The Company may reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint, grievance or notice, and you acknowledge that your timely assistance with the investigation would facilitate the redressal of the same.</span></p>

            <p dir="ltr"><strong>The name and title of the Grievance Redressal Officer of the Company has been published and is as follows:</strong></p>

            <p dir="ltr"><span>Name: Rohit Saxena</span></p>
            <p dir="ltr"><span>Email: </span><a href="mailto:grievanceofficer@vusport.com"><span>grievanceofficer@vusport.com</span></a></p>
            <p dir="ltr"><span>Address: Super Six Sports Gaming Private Limited:</span></p>
            <p dir="ltr"><span>D105-106, D Block, First Floor, Pioneer Urban Square, Sector 62, Gurgaon - 122102</span></p>

            <p dir="ltr"><span>The Grievance Officer is identified above pursuant to the provisions of applicable laws including but not limited to the Information Technology Act, 2000 and the Consumer Protection Act, 2019, and the Rules enacted under those laws.</span></p>

            <p dir="ltr"><span>The Company reserves the right to replace the Grievance Redressal Officer at its discretion through publication of the name and title of such replacement on the website, which replacement shall come into effect immediately upon publication.</span></p>
            <p><span><span><br /><br /></span></span></p>
            <p dir="ltr"><strong>PART B: Content and Streaming Services</strong></p>

            <p dir="ltr"><span>I. Content Services</span><span> - Content Services include access to free as well as behind the pay-wall content on the Platform. For behind the pay-wall content, a preview may be available to all viewers of the Platform, but access to certain informative articles and certain exclusive features of the Platform are available to paid subscribers of the Content Services alone. The extent, type, quantity, manner and duration of the Content Services put behind the paywall is at the sole discretion of the Company.</span></p>

            <p dir="ltr"><span>As a user, you may opt to avail access to behind the paywall Content Services of a particular match of the tournament of the entire tournament or you may opt to avail a monthly or annual subscription which provides you access to all applicable Content Services for a specified period of time through the purchase of appropriate subscription packages.:</span></p>
            <p dir="ltr"><span>&nbsp;</span></p>

            <p dir="ltr"><span>The Company can anytime, at its sole discretion, make behind the paywall Content Services available for free access on the Platform.</span></p>

            <p dir="ltr"><span>The access and use of any free or behind the paywall Content Services does not in any manner guarantee you any kind of winning on any platform where the information from such Content Services may be applied by you. The usage of the Content Services by you is strictly for personal and non-commercial use provided that you maintain all copyright and other notices contained therein.</span></p>

            <p dir="ltr"><span>II. Streaming Services -</span><span> We may provide Streaming Services on our Platform which may also be available for viewing on the affiliate platforms provided by the Company. Streaming Services include access to free as well behind the pay-wall video content on the Platform. As a user, you may opt to avail access to behind the paywall video content of specific matches of a tournament, the entire tournament or you may opt to avail a monthly or annual subscription which provides you access to all applicable Streaming Services for a specific period of time through the purchase of appropriate subscription packages.</span></p>

            <p dir="ltr"><span>The Company can anytime at its sole discretion make behind the paywall Streaming Services available for free access on the Platform.</span></p>

            <p dir="ltr"><span>In case of certain selected content available for viewing on the Platform behind the pay wall, the Company may offer you at its a discretion a free trial period (&ldquo;</span><span>Free Trial</span><span>&rdquo;). The Free-Trial may be for such duration and subject to certain terms and conditions as determined by the Company. Such Free Trial shall may be provided by the Company only to first-time subscribers of the Platform and not in cases of renewal of membership or activation of another account from a physical address and/or e-mail address and/or device which is linked to an already existing account, etc. Post the expiry of the Free Trial period, you shall need to purchase the appropriate subscription package to access behind the paywall video content on the Platform.</span></p>

            <p dir="ltr"><span>The Company may offer an ad-free upgrade for any of the subscription packages. An Ad- Free upgrade of the subscription package may give you access to ad-free behind the pay-wall video content on the Platform in the manner determined by the Company. The ad -free upgrade will not show you mid-roll ads while accessing behind the pay-wall video content on the Platform. Users who upgrade to an ad free experience will continue to see non-mid- roll ads while accessing behind the pay-wall video content including without limitation L-Bands, static banner, Astons etc. The Company can anytime at its sole discretion disable the ad-free upgrade for any of the subscription packages.</span></p>

            <p dir="ltr"><strong>Availability of Services</strong></p>
            <p><strong>&nbsp;</strong></p>
            <p dir="ltr"><span>The nature of Services made available to you is subject to change, the final decision of which lies with the Company. The Company may vary the access rights of users engaged in accessing the Platform without a paid subscription at any time at the Company's sole discretion. With respect to paid subscribers, any revision of access rights will take effect on the completion of such user's current subscription term.</span></p>

            <p dir="ltr"><span>Services may differ geographically and based on the device from which you subscribe. The prices of behind the paywall Services may also vary in time. With respect to recurring monthly and annual subscription packages, any price changes are effective as of the beginning of the next subscription period. The Company reserves the right to introduce new subscription packages from time to time at its own discretion.</span></p>

            <p dir="ltr"><span>The Company will try to process your subscription promptly but does not guarantee that your subscription will be activated by any specified time. By submitting your payment and other subscription details, you are making an offer to us to buy a subscription. Your offer will only be accepted by us and a contract formed when we have successfully verified your payment details and email address, at which point we will provide you with access to your subscription. The Company reserves the right to reject any offer in its discretion, for any or no reason.</span></p>

            <p dir="ltr"><span>The Company may, as part of its promotional activities, offer users some promotional offers subject to the fulfilment of the terms and conditions of such promotional offers as may be specified by the Company from time to time.</span></p>

            <p dir="ltr"><span>The Services and any content viewed through the Services are for your personal and non-commercial use only and may not be shared with third parties. The Services may be accessed or viewed on a maximum number of devices per account as specified by the Company at any given point of time. You agree not to use the Services for public performances. During the term of your subscription package, as applicable, we grant you a limited, non-exclusive, non-transferable, non-sublicensable right to access the Platforms and view the Services. Except for the foregoing, no right, title or interest shall be transferred to you.</span></p>

            <p dir="ltr"><span>You will be responsible for ensuring that you have a high-speed working Internet connection with adequate bandwidth and the requisite media software and a compatible browser to access the Services. You will not be eligible for any refunds if you are unable to view any Services purchased due to poor internet connectivity issues or technical errors at your end.</span></p>

            <p dir="ltr"><span>If you breach any provisions of these Terms, we have the right to terminate your access to our Platform, or remove or ban you from accessing our Platform or any other offering under these Terms.&nbsp;</span></p>

            <p dir="ltr"><strong>Pricing</strong></p>

            <p dir="ltr"><span>The fee and payment terms for purchase of a subscription package shall be as specified on the Platform at the time of purchase. The fee may vary across different Services and different sports offered on the Platform. Eligibility for any discounts or promotions shall be ascertained at the time of purchase a subscription package. All transactions are in Indian Rupees unless otherwise specified.</span></p>

            <p dir="ltr"><span>We may change our offerings and the price of our Services from time to time. You agree to pay the fees at the rates applicable at the time and place of your purchase.</span></p>

            <p dir="ltr"><span>You may not return, exchange or cancel a subscription package unless otherwise specified in these Terms and no refunds will be issued to you for any reason whatsoever. If you choose to cancel your purchased subscription package, you will continue to have access to all behind the paywall content offered under that subscription package during its validity period. We do not provide refunds or credits for any partially utilised subscription packages or unwatched or unutilised services provided under a specific subscription package.</span></p>

            <p dir="ltr"><span>If we inadvertently state an incorrect price to you whether online or otherwise, we are not obliged to provide you with a subscription package at that price, even if we have mistakenly accepted your offer to buy a subscription package at that price, and we reserve the right to subsequently notify you of any pricing error. We will always act in good faith in determining whether a genuine pricing error has occurred. In case of any inadvertent pricing error, the Company at its sole discretion reserves the right to rectify the error/provide alternative services/ refund the money as deemed fit.</span></p>

            <p dir="ltr"><span>In addition to any fee you pay to purchase a subscription package, you are responsible for paying any other charges necessary for processing your offer for the purchase of such a subscription package. You are also responsible for paying for any internet connection or other telecommunications charges that you may incur by accessing the Platform or using the Services.&nbsp;</span></p>

            <p dir="ltr"><strong>Payment</strong></p>

            <p dir="ltr"><span>When you purchase a subscription package you must provide us with complete and accurate payment information. By submitting payment details, you promise that you are entitled to purchase a subscription package using those payment details. If we do not receive payment authorization or any authorization is subsequently cancelled, we may immediately cancel your subscription package. In suspicious circumstances, we may contact the issuing bank/payment provider and/or law enforcement authorities or other appropriate third parties. If you are entitled to a refund in accordance with the terms and conditions set out below, we will credit that refund to the card or other payment method you used for payment unless it has expired. In such case we will contact you through the email address and/or mobile phone number provided by you at the time of registration.</span></p>

            <p dir="ltr"><span>All payments for the purchase of a subscription package on the Platform shall be made through such other platform as may be determined by the Company from time to time in its sole and absolute discretion. Accordingly, your usage of such third-party services shall be in accordance with the terms and conditions adopted and implemented by such service provider, and the Company shall not be responsible for any failed or incomplete fulfilment of any payment instructions issued by you through such service provider or any actions taken by the Company in respect of the same.</span></p>

            <p dir="ltr"><span>The fee for a particular subscription package will be billed at the beginning of the subscription period, and in case of a monthly and annual subscription packages shall be renewed automatically on the expiry date unless and until cancelled by you or otherwise suspended or discontinued pursuant to these Terms. Unless you cancel your subscription package before your billing date, we will automatically charge the monthly or annual subscription fee, as applicable, for the next billing cycle to the payment method we have on file.</span></p>

            <p dir="ltr"><span>If a payment for a monthly or annual subscription package is not successfully settled and you do not terminate your account, we may suspend or terminate your access to the relevant subscription package until your payment is successful.</span></p>

            <p dir="ltr"><span>We reserve the right to cancel your subscription package if you have previously been banned or removed from our Platform for any reason. We also reserve the right at any time to change our fees and payment procedures, including payment options and terms, either immediately upon posting on our Platform or by other notice to you.</span></p>

            <p dir="ltr"><span>If you believe someone has accessed the Platform using your account and password without your authorization, please immediately notify us at </span><a href="mailto:helpdesk@vusport.com"><span>helpdesk@vusport.com</span></a><span> .</span></p>

            <p dir="ltr"><strong>Invoice and Communication Regarding Payment for Streaming Service</strong></p>

            <p dir="ltr"><span>The Company will send confirmation of your purchase of a subscription package via SMS or email provided by you at the time of registration. The invoice will also be available for download on your user 'Account' page on the Platform.</span></p>

            <p dir="ltr"><span>The Company may contact you through SMS, call or email for any pending or failed purchase of a subscription package, to be aware of your preference for concluding the purchase and also to help you with the same.</span></p>

            <p dir="ltr"><span>You hereby unconditionally consent that such communications via SMS and email by the Company are:</span></p>

            <p dir="ltr"><span>a. upon your request and authorization;</span></p>
            <p dir="ltr"><span>b. 'transactional' and not an 'unsolicited commercial communication' as per the guidelines of Telecom Regulation Authority of India (TRAI), and</span></p>
            <p dir="ltr"><span>c. in compliance with the relevant guidelines of TRAI or such other authority in India and abroad.</span></p>

            <p dir="ltr"><span>You shall indemnify the Company against all types of losses and damages incurred by the Company due to any action taken by TRAI, Access Providers (as defined under the TRAI regulations) or any other applicable authority due to any erroneous complaint raised by you on the Company with respect to the communications mentioned above or due to an incorrect phone number or email id being provided by you for any reason whatsoever.&nbsp;</span></p>

            <p dir="ltr"><strong>Refunds of Payment</strong></p>

            <p dir="ltr"><span>Except in the limited circumstances specified below, no refunds will be issued to you in respect of subscription package, for any reason whatsoever. We reserve the right to issue refunds at our sole discretion. If we do issue a refund, we are under no obligation to issue the same or similar refund in the future.</span></p>

            <p dir="ltr"><span>The subscription fee payable with respect to monthly and annual subscription packages are non-refundable irrespective of whether the subscription has been used by the user.&nbsp;</span></p>

            <p dir="ltr"><span>It is clarified that a user will not be eligible for any refunds in the event of an operational or technical issue at the user&rsquo;s end which results in the user being unable to view either a part or the whole of the purchased Services.</span></p>

            <p dir="ltr"><strong>Content Services</strong></p>

            <p dir="ltr"><span>You may raise a request for refund only in case you were not able to view the entire content behind paywall on the purchase of a match pass or tour pass solely due to a technical error/glitch attributable to the Company. You may raise a ticket requesting for a refund through the Support/Help section on the Platform. The Company shall review each request and decide on the eligibility of the request for a refund.&nbsp;</span></p>
        </div>
    )
}

export default TermsAndConditions;
