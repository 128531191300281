import React from 'react'
import styles from '../styles/seasonplayerstats.css';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const SeasonPlayerStats = () => {
  useEffect(() => {
    window.opta_settings = {
      subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
      language: 'en_GB',
      timezone: 'Europe/London',
      link_callback: function (params) {
        console.log(params);
        //				return params.base_url;

      }
    };
  }, [])
  const location = useLocation();
  const seasonPlayerStatsWidget = Object.fromEntries(new URLSearchParams(location.search));

  return (
    <div className='seasonPlayerStats'>
      <opta-widget style={styles} widget={seasonPlayerStatsWidget.widget} competition={seasonPlayerStatsWidget.competition} season={seasonPlayerStatsWidget.season} team={seasonPlayerStatsWidget.team} player={seasonPlayerStatsWidget.player} template={seasonPlayerStatsWidget.template} navigation={seasonPlayerStatsWidget.navigation} default_nav={seasonPlayerStatsWidget.default_nav} show_split_stats={seasonPlayerStatsWidget.show_split_stats} round_navigation={seasonPlayerStatsWidget.round_navigation} show_general={seasonPlayerStatsWidget.show_general} show_distribution={seasonPlayerStatsWidget.show_distribution} show_attack={seasonPlayerStatsWidget.show_attack} show_defence={seasonPlayerStatsWidget.show_defence} show_discipline={seasonPlayerStatsWidget.show_discipline} show_goalkeeping={seasonPlayerStatsWidget.show_goalkeeping} team_naming={seasonPlayerStatsWidget.team_naming} player_naming={seasonPlayerStatsWidget.player_naming} show_logo={seasonPlayerStatsWidget.show_logo} show_title={seasonPlayerStatsWidget.show_title} breakpoints={seasonPlayerStatsWidget.breakpoints} sport={seasonPlayerStatsWidget.sport}></opta-widget>
      <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
    </div>
  )
}

export default SeasonPlayerStats
