import React, { useEffect } from "react";
import '../scss/playerp.scss';
import { useLocation, useSearchParams } from 'react-router-dom';


const PlayerPage = () => {

    const [params] = useSearchParams()
    console.log(params);

    useEffect(() => {
        window.opta_settings = {
            subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
            language: 'en_GB',
            timezone: 'Europe/London',
            link_callback: function (params) {
                console.log(params);
                //				return params.base_url;

            }
        };
    }, [])

    const location = useLocation();
    const playerPageWidget = Object.fromEntries(new URLSearchParams(location.search));
    console.log(params);

    return (
        <div className="player">
            <opta-widget widget="player_profile" competition={params.get('competition')} season={params.get('season')} team={params.get('team')} player={params.get('player')} template={playerPageWidget.template} show_images="true" show_country="true" show_flags="true" date_format="D MMMM YYYY" height_units="m" weight_units="kg" player_naming={playerPageWidget.player_naming} show_logo={playerPageWidget.show_logo} show_title="true" breakpoints={playerPageWidget.breakpoints} sport={playerPageWidget.sport}></opta-widget>

            <opta-widget widget="season_player_stats" competition={params.get('competition')} season={params.get('season')} team={params.get('team')} player={params.get('player')} template={playerPageWidget.template} navigation="dropdown" default_nav="1" show_split_stats="false" round_navigation="tabs" show_general="true" show_distribution="true" show_attack="true" show_defence="true" show_discipline="true" show_goalkeeping="true" team_naming="full" player_naming={playerPageWidget.player_naming} show_logo={playerPageWidget.show_logo} show_title="false" breakpoints={playerPageWidget.breakpoints} sport={playerPageWidget.sport}></opta-widget>

            <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
        </div>
    )
}

export default PlayerPage
