import React from 'react'

const ContactUs = () => {
    return (
        <div
            className='contact-us-page'
            style={{
                padding: '20px',
                maxWidth: '1100px',
                margin: 'auto',
                height: 'auto',
                position: 'relative',
                top: '0px',
                // display: 'flex',
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            <h2 style={{color: '#119AE8'}}>Contact Us</h2>
            <p>Do you also believe in the power of data to revolutionize sports?</p>
            <p>Are you an ardent fan and want to make a difference in the field of sports?</p>
            <p>Are you a content creator & looking for a sporting platform where you can
                flourish?
            </p>
            <p>Come join us in this revolution!</p>
            <p>For any queries, reach out to us on helpdesk@vusport.com</p>
        </div>
    )
}

export default ContactUs;