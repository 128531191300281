import React, { useEffect } from 'react'
import '../scss/MatchPrev.scss';
import { useLocation, useSearchParams } from 'react-router-dom';


const MatchPreview = () => {

  //const [width, setWidth] = useState<number>(window.innerWidth);
  const [params] = useSearchParams()
  console.log(params);



  // function handleWindowSizeChange() {
  //     setWidth(window.innerWidth);
  // }
  // useEffect(() => {
  //     window.addEventListener('resize', handleWindowSizeChange);
  //     return () => {
  //         window.removeEventListener('resize', handleWindowSizeChange);
  //     }
  // }, []);

  // const isMobile = width <= 768;

  useEffect(() => {
    window.opta_settings = {
      subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
      language: 'en_GB',
      timezone: 'Europe/London',
      link_callback: function (params) {
        console.log(params);

      }
    };
  }, [])

  const location = useLocation();
  const matchPreviewWidget = Object.fromEntries(new URLSearchParams(location.search));

  return (

    <div className="matchprev">
      <opta-widget
        widget="match_preview" competition={params.get('competition')} season={params.get('season')} match={params.get('match')} template={matchPreviewWidget.template} navigation={matchPreviewWidget.navigation} default_nav={matchPreviewWidget.default_nav} show_match_header={matchPreviewWidget.show_match_header} show_crests={matchPreviewWidget.show_crests} show_competition_name={matchPreviewWidget.show_competition_name} show_venue={matchPreviewWidget.show_venue} show_date={matchPreviewWidget.show_date} date_format={matchPreviewWidget.date_format} time_format={matchPreviewWidget.time_format} show_form={matchPreviewWidget.show_form} show_fixtures={matchPreviewWidget.show_fixtures} show_previous_meetings={matchPreviewWidget.show_previous_meetings} show_top_scorers={matchPreviewWidget.show_top_scorers} show_tied_scorers={matchPreviewWidget.show_tied_scorers} show_images={matchPreviewWidget.show_images} competition_naming={matchPreviewWidget.competition_naming} team_naming={matchPreviewWidget.team_naming} player_naming={matchPreviewWidget.player_naming} show_logo={matchPreviewWidget.show_logo} show_title={matchPreviewWidget.show_title} breakpoints={matchPreviewWidget.breakpoints} sport={matchPreviewWidget.sport}></opta-widget>
      <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
    </div>
  )
}

export default MatchPreview
