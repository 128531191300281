import React from 'react'
import styles from '../styles/fixtures.css';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const Fixutres = () => {
  useEffect(() => {
    window.opta_settings= {
			subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
			language: 'en_GB',
			timezone: 'Europe/London',
			link_callback: function (params) {
				console.log(params);
//				return params.base_url;

			}
		};
  }, [])
    const location = useLocation();
    const fixturesWidget = Object.fromEntries(new URLSearchParams(location.search));
    console.log(fixturesWidget);

  return (
    <div id="fixturesId">
      <opta-widget style={styles} widget={fixturesWidget.widget} fixtures_type={fixturesWidget.fixtures_type} competition={fixturesWidget.competition} season={fixturesWidget.season} team={fixturesWidget.team} match_link={fixturesWidget.match_link} template={fixturesWidget.template} live={fixturesWidget.live} show_venue={fixturesWidget.show_venue} match_status={fixturesWidget.match_status} grouping={fixturesWidget.grouping} show_grouping={fixturesWidget.show_grouping} navigation={fixturesWidget.navigation} default_nav={fixturesWidget.default_nav} start_on_current={fixturesWidget.start_on_current} sub_grouping={fixturesWidget.sub_grouping} show_subgrouping={fixturesWidget.show_subgrouping} order_by={fixturesWidget.order_by} show_crests={fixturesWidget.show_crests} date_format={fixturesWidget.date_format} time_format={fixturesWidget.time_format} month_date_format={fixturesWidget.month_date_format} competition_naming={fixturesWidget.competition_naming} team_naming={fixturesWidget.team_naming} pre_match={fixturesWidget.pre_match} show_live={fixturesWidget.show_live} show_logo={fixturesWidget.show_logo} show_title={fixturesWidget.show_title} breakpoints={fixturesWidget.breakpoints} sport={fixturesWidget.sport}></opta-widget>
      <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
    </div>
  )
}

export default Fixutres
