import React, { useEffect } from 'react';
import '../scss/seasonTeamStats.scss';
import { useLocation, useSearchParams } from 'react-router-dom';

const SeasonTeamStats = () => {

    const [params] = useSearchParams()
    console.log(params);

    useEffect(() => {
        window.opta_settings = {
            subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
            language: 'en_GB',
            timezone: 'Europe/London',
            link_callback: function (params) {
                console.log(params);

            }
        };
    }, [])

    const location = useLocation();
    const SeasonTeamStatsWidget = Object.fromEntries(new URLSearchParams(location.search));

    return (
        <div className='SeaonTeamStats'>
            <opta-widget widget="season_team_stats" competition={params.get('competition')} season={params.get('season')} team={params.get('team')} template={SeasonTeamStatsWidget.template} navigation={SeasonTeamStatsWidget.navigation} default_nav={SeasonTeamStatsWidget.default_nav} show_general={SeasonTeamStatsWidget.show_general} show_distribution={SeasonTeamStatsWidget.show_distribution} show_attack={SeasonTeamStatsWidget.show_attack} show_defence={SeasonTeamStatsWidget.show_defence} show_discipline={SeasonTeamStatsWidget.show_discipline} team_naming={SeasonTeamStatsWidget.team_naming} show_logo={SeasonTeamStatsWidget.show_logo} show_title={SeasonTeamStatsWidget.show_title} breakpoints={SeasonTeamStatsWidget.breakpoints} sport={SeasonTeamStatsWidget.sport}></opta-widget>
            <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
        </div>
    )
}

export default SeasonTeamStats
