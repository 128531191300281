import React from 'react'
import styles from '../styles/formations.css';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Formations = () => {
  useEffect(() => {
    window.opta_settings= {
			subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
			language: 'en_GB',
			timezone: 'Europe/London',
			link_callback: function (params) {
				console.log(params);
//				return params.base_url;

			}
		};
  }, [])
    const location = useLocation();
    const formationsWidget = Object.fromEntries(new URLSearchParams(location.search));

  return (
    <div>
      <opta-widget style={styles} widget={formationsWidget.widget} competition={formationsWidget.competition} season={formationsWidget.season} match={formationsWidget.match} template={formationsWidget.template} orientation={formationsWidget.orientation} show_match_header={formationsWidget.show_match_header} show_team_sheets={formationsWidget.show_team_sheets} show_competition_name={formationsWidget.show_competition_name} show_crests={formationsWidget.show_crests} show_pitch_crests={formationsWidget.show_pitch_crests} show_images={formationsWidget.show_images} show_subs={formationsWidget.show_subs} side={formationsWidget.side} player_naming={formationsWidget.player_naming} team_naming={formationsWidget.team_naming} show_logo={formationsWidget.show_logo} show_title={formationsWidget.show_title} breakpoints={formationsWidget.breakpoints} sport={formationsWidget.sport}></opta-widget>
      <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
    </div>
  )
}

export default Formations
