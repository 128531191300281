import React, { useEffect } from 'react';
import '../scss/teamS.scss';
import { useLocation, useSearchParams } from 'react-router-dom';

const TeamStats = () => {

    const [params] = useSearchParams()
    console.log(params);

    useEffect(() => {
        window.opta_settings = {
            subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
            language: 'en_GB',
            timezone: 'Europe/London',
            link_callback: function (params) {
                console.log(params);

            }
        };
    }, [])

    const location = useLocation();
    const TeamStatsWidget = Object.fromEntries(new URLSearchParams(location.search));

    return (
        <div className='teamS'>
            <opta-widget widget="matchstats_pro" competition={params.get('competition')} season={params.get('season')} match={params.get('match')} template={TeamStatsWidget.template} live={TeamStatsWidget.live} navigation={TeamStatsWidget.navigation} default_nav={TeamStatsWidget.default_nav} show_match_header={TeamStatsWidget.show_match_header} show_score={TeamStatsWidget.show_score} show_halftime_score={TeamStatsWidget.show_halftime_score} show_crests={TeamStatsWidget.show_crests} show_competition_name={TeamStatsWidget.show_competition_name} graph_style={TeamStatsWidget.graph_style} show_date={TeamStatsWidget.show_date} date_format={TeamStatsWidget.date_format} show_timecontrols={TeamStatsWidget.show_timecontrols} show_timecontrols_buttons={TeamStatsWidget.show_timecontrols_buttons} show_timecontrols_bar={TeamStatsWidget.show_timecontrols_bar} extended_content={TeamStatsWidget.extended_content} rounding={TeamStatsWidget.rounding} competition_naming={TeamStatsWidget.competition_naming} team_naming={TeamStatsWidget.team_naming} show_live={TeamStatsWidget.show_live} show_logo={TeamStatsWidget.show_logo} show_title={TeamStatsWidget.show_title} breakpoints={TeamStatsWidget.breakpoints} sport={TeamStatsWidget.sport}></opta-widget>
            <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
        </div>
    )
}

export default TeamStats
