import React, { useEffect } from 'react';
import '../scss/leaguePlayerStats.scss';
import { useLocation, useSearchParams } from 'react-router-dom';


const LeaguePlayerStats = () => {

    const [params] = useSearchParams()
    console.log(params);

    useEffect(() => {
        window.opta_settings = {
            subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
            language: 'en_GB',
            timezone: 'Europe/London',
            link_callback: function (params) {
                console.log(params);

            }


        };
    }, [])

    const location = useLocation();
    const LeaguePlayerStatsWidget = Object.fromEntries(new URLSearchParams(location.search));

    return (
        <div className='LeaguePlayerStats'>
            <opta-widget

                widget="player_ranking"
                competition={params.get('competition')}
                season={params.get('season')}
                template={LeaguePlayerStatsWidget.template}
                navigation={LeaguePlayerStatsWidget.navigation}
                show_crests={LeaguePlayerStatsWidget.show_crests}
                show_images={LeaguePlayerStatsWidget.show_images}
                show_ranks={LeaguePlayerStatsWidget.show_ranks}
                show_appearances={LeaguePlayerStatsWidget.show_appearances}
                visible_categories={LeaguePlayerStatsWidget.visible_categories}
                limit={LeaguePlayerStatsWidget.limit}
                hide_zeroes={LeaguePlayerStatsWidget.hide_zeroes}
                show_team_names={LeaguePlayerStatsWidget.show_team_names}
                team_naming={LeaguePlayerStatsWidget.team_naming}
                player_naming={LeaguePlayerStatsWidget.player_naming}
                player_link="https://opta.vusport.com/playerpage"
                show_logo={LeaguePlayerStatsWidget.show_logo}
                show_title={LeaguePlayerStatsWidget.show_title}
                breakpoints={LeaguePlayerStatsWidget.breakpoints}
                sport={LeaguePlayerStatsWidget.sport}>

            </opta-widget>

            <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
        </div>
    )
}

export default LeaguePlayerStats
