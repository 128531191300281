import React from 'react'
import '../scss/teamComparison.scss';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

const TeamComparison = () => {

    const [params] = useSearchParams()
    console.log(params);

    useEffect(() => {
        window.opta_settings = {
            subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
            language: 'en_GB',
            timezone: 'Europe/London',
            link_callback: function (params) {
                console.log(params);
                //				return params.base_url;

            }
        };
    }, [])
    const location = useLocation();
    const teamComparisonWidget = Object.fromEntries(new URLSearchParams(location.search));

    return (

        <div className="teamComparison">
            <opta-widget widget={teamComparisonWidget.widget} fixtures_type={teamComparisonWidget.fixtures_type} competition={params.get('competition')} season={params.get('season')} team={params.get('team')} template={teamComparisonWidget.template} navigation={teamComparisonWidget.navigation} default_nav={teamComparisonWidget.default_nav} show_selects={teamComparisonWidget.show_selects} show_crests={teamComparisonWidget.show_crests} show_graphs={teamComparisonWidget.show_graphs} graph_style={teamComparisonWidget.graph_style} competition_naming={teamComparisonWidget.competition_naming} team_naming={teamComparisonWidget.team_naming} show_logo={teamComparisonWidget.show_logo} show_title={teamComparisonWidget.show_title} breakpoints={teamComparisonWidget.breakpoints} sport={teamComparisonWidget.sport}></opta-widget>
            <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
        </div>
    )
}

export default TeamComparison
