import React, { useEffect } from 'react';
import styles from '../styles/matchdetail.css';
import { useLocation, useSearchParams } from 'react-router-dom';

const MatchDetail = () => {

    const [params] = useSearchParams()
    console.log(params);

    useEffect(() => {
        window.opta_settings = {
            subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
            language: 'en_GB',
            timezone: 'Europe/London',
            link_callback: function (params) {
                console.log(params);

            }
        };
    }, [])

    const location = useLocation();
    const MatchDetailWidget = Object.fromEntries(new URLSearchParams(location.search));

    return (
        <div>
            <opta-widget style={styles} widget={MatchDetailWidget.widget} competition={params.get('competition')} season={params.get('season')} match={params.get('match')} template={MatchDetailWidget.template} live={MatchDetailWidget.live} orientation={MatchDetailWidget.orientation} vertical_timebar_height={MatchDetailWidget.vertical_timebar_height} group_substitutions={MatchDetailWidget.group_substitutions} show_match_header={MatchDetailWidget.show_match_header} show_score={MatchDetail.show_score} show_crests={MatchDetail.show_crests} show_assists={MatchDetailWidget.show_assists} show_team_formation={MatchDetailWidget.show_team_formation} show_clock={MatchDetailWidget.show_clock} show_period_dividers={MatchDetailWidget.show_period_dividers} show_halftime_score={MatchDetailWidget.show_halftime_score} show_date={MatchDetailWidget.show_date} date_format={MatchDetailWidget.date_format} show_attendance={MatchDetailWidget.show_attendance} show_images={MatchDetailWidget.show_images} show_referee={MatchDetailWidget.show_referee} show_venue={MatchDetailWidget.show_venue} show_cards={MatchDetailWidget.show_cards} show_goals={MatchDetailWidget.show_goals} show_penalties_missed={MatchDetailWidget.show_penalties_missed} show_subs={MatchDetailWidget.show_subs} show_goals_combined={MatchDetailWidget.show_goals_combined} show_shootouts={MatchDetailWidget.show_shootouts} show_competition_name={MatchDetailWidget.show_competition_name} competition_naming={MatchDetailWidget.competition_naming} team_naming={MatchDetailWidget.team_naming} player_naming={MatchDetailWidget.player_naming} show_live={MatchDetailWidget.show_live} show_logo={MatchDetailWidget.show_logo} show_title={MatchDetailWidget.show_title} breakpoints={MatchDetailWidget.breakpoints} sport={MatchDetailWidget.sport}></opta-widget>
            <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
        </div>
    )
}

export default MatchDetail
