import { useLocation } from 'react-router-dom';
import '../scss/standings.scss';
import { useEffect } from 'react';

const Standings = () => {
    const location = useLocation();
    const standingWidget = Object.fromEntries(new URLSearchParams(location.search));
    useEffect(() => {
        window.opta_settings = {
            subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
            language: 'en_GB',
            timezone: 'Europe/London',
            link_callback: function (params) {
                console.log(params);
                //				return params.base_url;

            }
        };
    }, [])


    return (
        <>

            <div className='standings'>
                <opta-widget widget={standingWidget.widget} competition={standingWidget.competition} season={standingWidget.season} match={standingWidget.match} team={standingWidget.team} template={standingWidget.template} live={standingWidget.live} form={standingWidget.form} default_nav={standingWidget.default_nav} side={standingWidget.side} show_crests={standingWidget.show_crests} data_detail={standingWidget.data_detail} competition_naming={standingWidget.competition_naming} team_naming={standingWidget.team_naming} team_link={standingWidget.team_link} date_format={standingWidget.date_format} show_live={standingWidget.show_live} show_logo={standingWidget.show_logo} show_title={standingWidget.show_title} breakpoints={standingWidget.breakpoints} sport={standingWidget.sport}></opta-widget>
                <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
            </div>


        </>
    )
}
export default Standings;