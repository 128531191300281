import React from 'react'
import '../scss/commentry.scss';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Commentry = () => {
  useEffect(() => {
    window.opta_settings = {
      subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
      language: 'en_GB',
      timezone: 'Europe/London',
      link_callback: function (params) {
        console.log(params);
        //				return params.base_url;

      }
    };
  }, [])
  const location = useLocation();
  const commentryWidget = Object.fromEntries(new URLSearchParams(location.search));
  console.log(commentryWidget);
  return (

    <div className="scroll">
      <opta-widget widget={commentryWidget.widget} competition={commentryWidget.competition} season={commentryWidget.season} match={commentryWidget.match} template={commentryWidget.template} live={commentryWidget.live} data_type={commentryWidget.data_type} order_by={commentryWidget.order_by} show_event_icons={commentryWidget.show_event_icons} show_minor_events={commentryWidget.show_minor_events} fixed_height_comments={commentryWidget.fixed_height_comments} show_live={commentryWidget.show_live} show_logo={commentryWidget.show_logo} show_title={commentryWidget.show_title} breakpoints={commentryWidget.breakpoints} sport={commentryWidget.sport}></opta-widget>
      <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
    </div>
  )
}

export default Commentry
