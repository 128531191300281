import React from 'react';
import './styles/loader.css';
import './'
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Standings from './components/standings';
import Commentry from './components/Commentry';
import Fixutres from './components/Fixutres';
import MatchPreview from './components/MatchPreview';
import MatchSummary from './components/MatchSummary';
import Formations from './components/Formations';
import SeasonPlayerStats from './components/SeasonPlayerStats';
import Layout from './components/Layout';
import PlayerProfile from './components/PlayerProfile';
import Timeline from './components/Timeline';
import TeamStats from './components/TeamStats';
import MatchDetail from './components/MatchDetail';
import PlayerPage from './components/PlayerPage';
import SeasonTeamStats from './components/SeasonTeamStats';
import LeaguePlayerStats from './components/LeaguePlayerStats';
import AboutUs from './components/Pages/AboutUs';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import ContactUs from './components/Pages/ContactUs';
import FAQ from './components/Pages/FAQ';
import TermsAndConditions from './components/Pages/TermsAndConditions';
import FaqAccordian from './components/FAQ_Acoordian/FaqAccordian';
import TeamComparison from './components/TeamComparison';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Layout />} />
          <Route exact path="/layout" element={<Layout />} />
          <Route exact path="/standings" element={<Standings />} />
          <Route exact path="/fixtures" element={<Fixutres />} />
          <Route exact path="/matchpreview" element={<MatchPreview />} />
          <Route exact path="/matchsummary" element={<MatchSummary />} />
          <Route exact path="/playerprofile" element={<PlayerProfile />} />
          <Route exact path="/formations" element={<Formations />} />
          <Route exact path="/seasonplayerstats" element={<SeasonPlayerStats />} />
          <Route exact path="/commentry" element={<Commentry />} />
          <Route exact path="/timeline" element={<Timeline />} />
          <Route exact path="/teamstats" element={<TeamStats />} />
          <Route exact path="/matchdetail" element={<MatchDetail />} />
          <Route exact path="/playerpage" element={<PlayerPage />} />
          <Route exact path="/seasonteamstats" element={<SeasonTeamStats />} />
          <Route exact path="/leagueplayerstats" element={<LeaguePlayerStats />} />
          <Route exact path="/teamComparison" element={<TeamComparison />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/contactus" element={<ContactUs />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/tnc" element={<TermsAndConditions />} />
          <Route exact path="/faqaccordian" element={<FaqAccordian />} />


          contactus
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
