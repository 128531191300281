import React, { useEffect } from 'react'

import '../../scss/aboutUs.scss'

const AboutUs = () => {
    // useEffect(() => {
    //     // console.log('asds', window.document.html.style.background = 'red');

    //     // document.html.style = 'background: red;';

    // }, [])
    return (
        <div className='about-us-page' style={{ padding: '5px 20px 20px 20px', maxWidth: '1100px', margin: 'auto', }}>

            <p>VUSport is an online platform devoted to all things Sports, launched with the intention of bringing joy to hard-core sports fans. The brand is backed by the legendary AB De Villiers and Sunil Chhetri as the brand ambassadors.</p>
            <p>Our mission is to become a world-class provider of analytics-based sports content and educate the average sports fan with deeper engagement levels. Having rapidly mustered a healthy online presence since our brand launch, we are well on our way towards becoming a premier hub for sports-led content and data-backed insights for sports enthusiasts everywhere.</p>
            <p>Our commitment to top-notch quality extends to our employee centricity and we constantly strive towards becoming a first-rate destination for outstanding talent.</p>
            <p>VUSport is a brand from the house of Super Six Sports Gaming Private Limited, an India based Sports Tech Organization, the parent company of brands such as Fantasy Akhada, one of India's top 3 Fantasy Sports Platforms.</p>
        </div>
    )
}

export default AboutUs;
