import React, { useEffect } from 'react';
import '../scss/timeline.scss';
import { useLocation, useSearchParams } from 'react-router-dom';

const Timeline = () => {
    const [params] = useSearchParams()
    console.log(params);


    useEffect(() => {
        window.opta_settings = {
            subscription_id: '205f6e6f180f6d489b8abf9494abca7a',
            language: 'en_GB',
            timezone: 'Europe/London',
            link_callback: function (params) {
                console.log(params);

            }

            
        };
    }, [])

    const location = useLocation();
    const TimelineWidget = Object.fromEntries(new URLSearchParams(location.search));

    return (
        <div className='Timeline'>
            <opta-widget widget="match_summary" competition={params.get('competition')} season={params.get('season')} match={params.get('match')} template={TimelineWidget.template} live={TimelineWidget.live} show_match_header={TimelineWidget.show_match_header} show_score={TimelineWidget.show_score} show_attendance={TimelineWidget.show_attendance} show_date={TimelineWidget.show_date} date_format={TimelineWidget.date_format} show_cards={TimelineWidget.show_cards} show_crests={TimelineWidget.show_crests} show_team_formation={TimelineWidget.show_team_formation} show_goals={TimelineWidget.show_goals} show_goals_combined={TimelineWidget.show_goals_combined} show_penalties_missed={TimelineWidget.show_penalties_missed} show_halftime_score={TimelineWidget.show_halftime_score} show_referee={TimelineWidget.show_referee} show_subs={TimelineWidget.show_subs} show_venue={TimelineWidget.show_venue} order_by={TimelineWidget.order_by} show_shootouts={TimelineWidget.show_shootouts} show_tooltips={TimelineWidget.show_tooltips} show_images={TimelineWidget.show_images} show_competition_name={TimelineWidget.show_competition_name} competition_naming={TimelineWidget.competition_naming} team_naming={TimelineWidget.team_naming} player_naming={TimelineWidget.player_naming} show_live={TimelineWidget.show_live} show_logo={TimelineWidget.show_logo} show_title={TimelineWidget.show_title} breakpoints={TimelineWidget.breakpoints} sport={TimelineWidget.sport}></opta-widget>
            <script src="https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js"></script>
        </div>
    )
}

export default Timeline
